import React from "react";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/Typography";
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CardActions from '@material-ui/core/CardActions';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import { get } from "../shared/AuthLogic";

class Vacatures extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vacatures: [],
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
            baseUrl: props.url,
            authenticate: props.authenticate,
            page: 1,
            page_size: 10,
            search: "",
            elektromech: false,
            infor: false,
            chem: false,
            bouw: false,
            pk: props.pk,
            auto: false,
            land: false,
            eict: false,
            etech: false,
            job: props.soort === "job" || props.soort === "both" ? true : false,
            internship: props.soort === "internship" || props.soort === "both" ? true : false,
        }
        this.getItems();
    }

    FilterSearch = (vac) => {
        this.setState({ page: 1 });
        if (this.state.search === "") {
            return true;
        }
        return (vac.title.toLowerCase().includes(this.state.search.toLowerCase()) || vac.short_desc.toLowerCase().includes(this.state.search.toLowerCase()) || vac.company_name.toLowerCase().includes(this.state.search.toLowerCase()))
    }

    FilterSliders = (vac) => {
        if (!this.state.infor && !this.state.chem && !this.state.bouw && !this.state.auto && !this.state.land && !this.state.eict && !this.state.etech && !this.state.elektromech) {
            return true;
        }
        return (this.state.infor && vac.doelgroep.includes("infor")) || (this.state.chem && vac.doelgroep.includes("chem")) || (this.state.bouw && vac.doelgroep.includes("bouw")) || (this.state.auto && vac.doelgroep.includes("auto")) || (this.state.land && vac.doelgroep.includes("land")) || (this.state.eict && vac.doelgroep.includes("eict")) || (this.state.etech && vac.doelgroep.includes("etech")) || (this.state.elektromech && vac.doelgroep.includes("elektromech"))
    }

    FilterSoort = (vac) => {
        return (this.state.job && vac.soort === "job") || (this.state.internship && vac.soort === 'stage')
    }

    Filter = (vacancies) => {
        return vacancies.filter((vac) => {
            return this.FilterSliders(vac) && this.FilterSearch(vac) && (this.FilterSoort(vac));
        });
    }

    getItems = () => {
        get(this.state.baseUrl, this.state.authenticate).then(json => {
            if (json !== false) {
                this.setState({
                    page: 1,
                    vacatures: this.Filter(json).sort(() => 0.5 - Math.random())
                });
            }
        });
    }

    pagination = async (event, value) => {
        await this.setState({ page: value });
    }

    vacatureUrl = (vacature) => {
        if ("owner_pk" in vacature) {
            return "/student/companies/".concat(vacature.owner_pk).concat("/vacatures/")
        }
    }

    render() {
        return (
            <div>
                <Hidden lgDown>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 110px)", marginTop: "50px" }} justify="center">
                        <Grid item xs={2} style={{ marginTop: "-20px", marginRight: "5%" }}>
                            <Card>
                                <CardContent>
                                    <Typography>
                                        <b>Filter/Search</b>
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        label="Search"
                                        name="Search"
                                        size="small"
                                        variant="outlined"
                                        onChange={e => this.setState({ search: e.target.value })}
                                        value={this.state.search}
                                        onKeyPress={e => {
                                            if (e.key === "Enter") {
                                                this.getItems();
                                            }
                                        }}
                                    />
                                    <Divider style={{ marginTop: "20px" }} />
                                    <Typography>
                                        <b>Field</b>
                                    </Typography>
                                    <Tooltip title="Students who study computer science">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.infor}
                                                    onChange={e => this.setState({ infor: !this.state.infor })}
                                                    name="Computer science"
                                                    color="primary"
                                                />
                                            } label="Computer science"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study electromechanics">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.elektromech}
                                                    onChange={e => this.setState({ elektromech: !this.state.elektromech })}
                                                    name="Electromechanics"
                                                    color="primary"
                                                />
                                            } label="Electromechanics"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study chemistry">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.chem}
                                                    onChange={e => this.setState({ chem: !this.state.chem })}
                                                    name="Chemistry"
                                                    color="primary"
                                                />
                                            } label="Chemistry"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study structural engineering">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.bouw}
                                                    onChange={e => this.setState({ bouw: !this.state.bouw })}
                                                    name="Structural engineering"
                                                    color="primary"
                                                />
                                            } label="Structural engineering"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study automation">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.auto}
                                                    onChange={e => this.setState({ auto: !this.state.auto })}
                                                    name="Automation"
                                                    color="primary"
                                                />
                                            } label="Automation"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study Land surveyor">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.land}
                                                    onChange={e => this.setState({ land: !this.state.land })}
                                                    name="Land surveyor"
                                                    color="primary"
                                                />
                                            } label="Land surveyor"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study electronical-ICT">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.eict}
                                                    onChange={e => this.setState({ eict: !this.state.eict })}
                                                    name="Electronical-ICT"
                                                    color="primary"
                                                />
                                            } label="Electronical-ICT"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study electronical-ICT">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.etech}
                                                    onChange={e => this.setState({ etech: !this.state.etech })}
                                                    name="Electrotechnics"
                                                    color="primary"
                                                />
                                            } label="Electrotechnics"
                                        />
                                    </Tooltip>
                                    <Divider style={{ marginTop: "20px" }} />
                                    <Tooltip title="Job">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.job}
                                                    onChange={e => this.setState({ job: !this.state.job })}
                                                    name="Job"
                                                    color="primary"
                                                />
                                            } label="Job"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Internship">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.internship}
                                                    onChange={e => this.setState({ internship: !this.state.internship })}
                                                    name="Internship"
                                                    color="primary"
                                                />
                                            } label="Internship"
                                        />
                                    </Tooltip>
                                    <Divider style={{ marginTop: "20px" }} />
                                </CardContent>
                                <CardActions>
                                    <Grid container justify="center">
                                        <Button variant="contained" onClick={this.getItems} disabled={false} style={{ marginBottom: "10px" }}>
                                            Search
                                        </Button>
                                    </Grid>

                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={7} style={{}}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="h3" style={{ marginBottom: "20px" }}>
                                        My vacancies
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "right" }}>
                                    <Link href={"/company/companies/" + this.state.pk + "/vacatures/add"}>
                                        <Typography style={{ marginTop: "20px" }}>
                                            <AddCircleRoundedIcon /><b>Create a new vacancy</b>
                                        </Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                            {this.state.vacatures.length === 0 ? <Typography>No results</Typography> : <div />}
                            {this.state.vacatures.map((vacature, index) => {
                                if (vacature.company_name !== false && index < this.state.page * this.state.page_size && index >= (this.state.page - 1) * this.state.page_size) {
                                    return <a href={"/company/vacatures/" + vacature.pk + "/edit/"}>
                                        <Card variant="outlined">
                                            <CardContent>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            <b>{vacature.title}</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                                                        {!vacature.is_visible ?
                                                            <Tooltip title="This vacancy is not visible, set it to visible for it to be seen">
                                                                <Chip style={{marginRight: "10px"}} label="Inactive" color="secondary" />
                                                            </Tooltip>
                                                            : <div />}
                                                        {vacature.bijlage === null ?
                                                            <Tooltip title="This vacancy has no additional pdf file">
                                                                <Chip style={{marginRight: "10px"}} label="No pdf" color="primary" />
                                                            </Tooltip>
                                                            : <div />}
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </a>
                                } else {
                                    return <div />;
                                }
                            })}
                        </Grid>
                        <Grid item xs={12} >
                            <Pagination style={{ justifyContent: "center", display: 'flex', alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}
                                count={Math.ceil(this.state.vacatures.length / this.state.page_size)} shape="rounded" color="primary"
                                page={this.state.page}
                                onChange={this.pagination} />
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden xlUp>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 110px)", marginTop: "50px" }} justify="center">
                        <Grid item xs={4} style={{ marginTop: "-20px", marginRight: "5%" }}>
                            <Card>
                                <CardContent>
                                    <Typography>
                                        <b>Filter/Search</b>
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        label="Search"
                                        name="Search"
                                        size="small"
                                        variant="outlined"
                                        onChange={e => this.setState({ search: e.target.value })}
                                        value={this.state.search}
                                        onKeyPress={e => {
                                            if (e.key === "Enter") {
                                                this.getItems();
                                            }
                                        }}
                                    />
                                    <Divider style={{ marginTop: "20px" }} />
                                    <Typography>
                                        <b>Field</b>
                                    </Typography>
                                    <Tooltip title="Students who study computer science">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.infor}
                                                    onChange={e => this.setState({ infor: !this.state.infor })}
                                                    name="Computer science"
                                                    color="primary"
                                                />
                                            } label="Computer science"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study electromechanics">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.elektromech}
                                                    onChange={e => this.setState({ elektromech: !this.state.elektromech })}
                                                    name="Electromechanics"
                                                    color="primary"
                                                />
                                            } label="Electromechanics"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study chemistry">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.chem}
                                                    onChange={e => this.setState({ chem: !this.state.chem })}
                                                    name="Chemistry"
                                                    color="primary"
                                                />
                                            } label="Chemistry"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study structural engineering">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.bouw}
                                                    onChange={e => this.setState({ bouw: !this.state.bouw })}
                                                    name="Structural engineering"
                                                    color="primary"
                                                />
                                            } label="Structural engineering"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study automation">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.auto}
                                                    onChange={e => this.setState({ auto: !this.state.auto })}
                                                    name="Automation"
                                                    color="primary"
                                                />
                                            } label="Automation"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study Land surveyor">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.land}
                                                    onChange={e => this.setState({ land: !this.state.land })}
                                                    name="Land surveyor"
                                                    color="primary"
                                                />
                                            } label="Land surveyor"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study electronical-ICT">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.eict}
                                                    onChange={e => this.setState({ eict: !this.state.eict })}
                                                    name="Electronical-ICT"
                                                    color="primary"
                                                />
                                            } label="Electronical-ICT"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study electronical-ICT">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.etech}
                                                    onChange={e => this.setState({ etech: !this.state.etech })}
                                                    name="Electrotechnics"
                                                    color="primary"
                                                />
                                            } label="Electrotechnics"
                                        />
                                    </Tooltip>
                                    <Divider style={{ marginTop: "20px" }} />
                                    <Tooltip title="Job">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.job}
                                                    onChange={e => this.setState({ job: !this.state.job })}
                                                    name="Job"
                                                    color="primary"
                                                />
                                            } label="Job"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Internship">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.internship}
                                                    onChange={e => this.setState({ internship: !this.state.internship })}
                                                    name="Internship"
                                                    color="primary"
                                                />
                                            } label="Internship"
                                        />
                                    </Tooltip>
                                    <Divider style={{ marginTop: "20px" }} />
                                </CardContent>
                                <CardActions>
                                    <Grid container justify="center">
                                        <Button variant="contained" onClick={this.getItems} disabled={false} style={{ marginBottom: "10px" }}>
                                            Search
                                        </Button>
                                    </Grid>

                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={7} style={{}}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="h3" style={{ marginBottom: "20px" }}>
                                        My vacancies
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "right" }}>
                                    <Link href={"/company/companies/" + this.state.pk + "/vacatures/add"}>
                                        <Typography style={{ marginTop: "20px" }}>
                                            <AddCircleRoundedIcon /><b>Create a new vacancy</b>
                                        </Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                            {this.state.vacatures.length === 0 ? <Typography>No results</Typography> : <div />}
                            {this.state.vacatures.map((vacature, index) => {
                                if (vacature.company_name !== false && index < this.state.page * this.state.page_size && index >= (this.state.page - 1) * this.state.page_size) {
                                    return <a href={"/company/vacatures/" + vacature.pk + "/edit/"}>
                                        <Card variant="outlined">
                                            <CardContent>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            <b>{vacature.title}</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                                                        {!vacature.is_visible ?
                                                            <Tooltip title="This vacancy is not visible, set it to visible for it to be seen">
                                                                <Chip style={{marginRight: "10px"}} label="Inactive" color="secondary" />
                                                            </Tooltip>
                                                            : <div />}
                                                        {vacature.bijlage === null ?
                                                            <Tooltip title="This vacancy has no additional pdf file">
                                                                <Chip style={{marginRight: "10px"}} label="No pdf" color="primary" />
                                                            </Tooltip>
                                                            : <div />}
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </a>
                                } else {
                                    return <div />;
                                }
                            })}
                        </Grid>
                        <Grid item xs={12} >
                            <Pagination style={{ justifyContent: "center", display: 'flex', alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}
                                count={Math.ceil(this.state.vacatures.length / this.state.page_size)} shape="rounded" color="primary"
                                page={this.state.page}
                                onChange={this.pagination} />
                        </Grid>
                    </Grid>
                </Hidden>
            </div>
        );
    }
}

export default Vacatures;
