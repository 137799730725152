import React from "react";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { refresh, getUrl } from "../shared/AuthLogic";
import {
    Link
} from "react-router-dom";

class AdminPortal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            name: "",
        }
    }

    create = (refreshed = false) => {
        let token;
        try {
            if (!localStorage.getItem('token')) throw new Error("tokenError");
            token = localStorage.getItem('token');
        }
        catch (err) {
            if (localStorage.getItem("token")) {
                localStorage.removeItem("token");
            }
            if (localStorage.getItem("refreshToken")) {
                localStorage.removeItem("refreshToken");
            }
            return false;
        }
        fetch(getUrl() + "jobbeurs/company/register/no_account/", {
            method: "post",
            headers: { "Authorization": "Bearer " + token,
            "Content-Type": 'application/json'
            },
            body: JSON.stringify({ "name": this.state.name })
        })
            .then(res => {
                if (res.status === 201) {
                    return res.json();
                } else if (res.status === 401 && !refreshed) {
                    return refresh().then(success => {
                        if (success) {
                            return this.create(true);
                        } else {
                            alert("Something went wrong, please contact ICT");
                            document.location.reload();
                        }
                    })
                }
                alert("Something went wrong, please contact ICT");
                document.location.reload();
            })
            .then(json => {
                document.location.href = "/company/companies/" + json.pk;
            })
    }

    render() {
        return (
            <div>
                <div style={{ minHeight: "calc(100vh - 80px - 150px)" }}>
                    <Grid container spacing={12} justify="center">
                        <Grid item xs={1} />
                        <Grid item xs={2} style={{ marginTop: "50px" }}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Typography color="textPrimary">Admin</Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={9} />
                        <Grid item xs={2} />
                        <Grid item xs={10} style={{ marginTop: "10px", fontSize: "16px" }}>
                            <Link to="/admin/add/">
                                Add company with a new account
                            </Link>
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={10} style={{ marginTop: "10px", fontSize: "16px" }}>
                            <Link onClick={() => this.setState({ dialogOpen: true })}>
                                Add company without a new account and add account later
                            </Link>
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={10} style={{ marginTop: "10px", fontSize: "16px" }}>
                            <Link to="/admin/search/">
                                Search all companies
                            </Link>
                        </Grid>
                    </Grid>
                    <Dialog
                        open={this.state.dialogOpen}
                        onClose={() => this.setState({ dialogOpen: false })}
                    >
                        <DialogTitle>{"Are you sure you want to create a company without an account?"}</DialogTitle>
                        <DialogContent>
                            <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                <TextField error={this.state.name.length > 50}
                                    fullWidth label="Company name" name="website" size="small" variant="outlined"
                                    onChange={e => this.setState({ name: e.target.value })}
                                    value={this.state.name}
                                    disabled={this.state.disabled}
                                    inputProps={{ maxLength: 50 }}
                                    style={{ maxWidth: "500px", marginBottom: "10px" }}
                                />
                            </Typography>
                            <DialogContentText>
                                This will make a dangling company (company without a login). Please do not spam these and add an account to them later!
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ dialogOpen: false })} color="Secondary">
                                Cancel
                            </Button>
                            <Button onClick={this.create} disabled={this.state.name.length === 0} color="primary" autoFocus>
                                Create
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }
}

export default AdminPortal;
