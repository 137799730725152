import React from "react";
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Card from "@material-ui/core/Card";
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { post } from "../shared/AuthLogic";
import Link from '@material-ui/core/Link';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

function listener(e) {
    this.setState({ bijlage: e.target.result, updated_bijlage: true });
}

function isWebsite(site) {
    if (site.length === 0) {
        return true;
    }
    let expression = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/g;
    return site.match(expression);
}

class CreateVacature extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            short_desc: "",
            profiel: "",
            locatie: "",
            client: "",
            aanbod: "",
            interesse: "",
            bijlage: null,
            company_name: "",
            doelgroep: [],
            functiebeschrijving: "",
            is_visible: true,
            logo: null,
            soort: "job",
            title: "",
            website: "",
            pk: props.par.pk,
            elektromech: false,
            infor: false,
            chem: false,
            bouw: false,
            auto: false,
            land: false,
            eict: false,
            etech: false,
            updated_bijlage: false,
            dialogOpen: false,
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,

        }
    }

    create = () => {
        this.setState({ failed: false, invalid: false, disabled: true });
        let obj = {
            short_desc: this.state.short_desc,
            functiebeschrijving: this.state.functiebeschrijving,
            is_visible: this.state.is_visible,
            soort: this.state.soort,
            title: this.state.title,
            website: this.state.website,
            profiel: this.state.profiel,
            aanbod: this.state.aanbod,
            interesse: this.state.interesse,
            locatie: this.state.locatie,
            client: this.state.client,
        };
        let doelgroep = []
        if (this.state.elektromech) {
            doelgroep.push("elektromech");
        }
        if (this.state.infor) {
            doelgroep.push("infor");
        }
        if (this.state.chem) {
            doelgroep.push("chem");
        }
        if (this.state.bouw) {
            doelgroep.push("bouw");
        }
        if (this.state.auto) {
            doelgroep.push("auto");
        }
        if (this.state.land) {
            doelgroep.push("land");
        }
        if (this.state.eict) {
            doelgroep.push("eict");
        }
        if (this.state.etech) {
            doelgroep.push("etech");
        }
        obj.doelgroep = doelgroep;
        if (this.state.updated_bijlage) {
            obj.bijlage = this.state.bijlage
        }
        post("jobbeurs/companies/" + this.state.pk + "/vacatures/add/", obj, true)
            .then(success => {
                if (success) {
                    document.location.href = "/company/companies/" + this.state.pk + "/vacatures/";
                } else {
                    alert("Something went wrong, if this keeps occurring please contact ict@hermesgent.be");
                }
            });
    };

    render() {
        return (
            <div>
                <Hidden lgDown>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 120px)" }} justify="center">
                        <Grid item xs={6} style={{ marginTop: "30px" }}>
                            <Grid item xs={12} style={{ marginTop: "10px" }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Link color="inherit" href={"/company/companies/" + this.state.pk + "/manage/"}>
                                                My company
                                            </Link>
                                            <Link color="inherit" href={"/company/companies/" + this.state.pk + "/vacatures/"}>
                                                Manage vacancies
                                            </Link>
                                            <Typography color="textPrimary">Create vacancy</Typography>
                                        </Breadcrumbs>
                                    </Grid>
                                </Grid>
                                <Card variant="outlined" style={{ marginBottom: "10px", marginTop: "20px" }}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={3} />
                                            <Grid item xs={6} style={{ textAlign: "center", marginTop: "20px" }}>
                                                <Typography variant="h3" component="h2" style={{}}>
                                                    <TextField error={this.state.title.length === 0} autoFocus
                                                        fullWidth label="Vacancy title" size="small" variant="outlined"
                                                        onChange={e => this.setState({ title: e.target.value })}
                                                        value={this.state.title}
                                                        disabled={this.state.disabled}
                                                        inputProps={{ maxLength: 100 }}
                                                        style={{ maxWidth: "600px" }}
                                                    />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} />
                                            <Grid item xs={12}>
                                                <Tooltip title="Make this page visible on our website">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.is_visible}
                                                                onChange={e => this.setState({ is_visible: !this.state.is_visible })}
                                                                name="visible"
                                                                color="primary"
                                                            />
                                                        } label="visible"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Make this vacancy for an internship or a job">
                                                    <FormControl >
                                                        <InputLabel>Vacancy type</InputLabel>
                                                        <Select
                                                            value={this.state.soort}
                                                            onChange={(e) => this.setState({ soort: e.target.value })}
                                                        >
                                                            <MenuItem value={"job"}>Job</MenuItem>
                                                            <MenuItem value={"stage"}>Internship</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Tooltip>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography>
                                                    Looking for:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Tooltip title="Students who study computer science">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.infor}
                                                                onChange={e => this.setState({ infor: !this.state.infor })}
                                                                name="Computer science"
                                                                color="primary"
                                                            />
                                                        } label="Computer science"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study electromechanics">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.elektromech}
                                                                onChange={e => this.setState({ elektromech: !this.state.elektromech })}
                                                                name="Electromechanics"
                                                                color="primary"
                                                            />
                                                        } label="Electromechanics"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study chemistry">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.chem}
                                                                onChange={e => this.setState({ chem: !this.state.chem })}
                                                                name="Chemistry"
                                                                color="primary"
                                                            />
                                                        } label="Chemistry"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study structural engineering">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.bouw}
                                                                onChange={e => this.setState({ bouw: !this.state.bouw })}
                                                                name="Structural engineering"
                                                                color="primary"
                                                            />
                                                        } label="Structural engineering"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study automation">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.auto}
                                                                onChange={e => this.setState({ auto: !this.state.auto })}
                                                                name="Automation"
                                                                color="primary"
                                                            />
                                                        } label="Automation"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study Land surveyor">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.land}
                                                                onChange={e => this.setState({ land: !this.state.land })}
                                                                name="Land surveyor"
                                                                color="primary"
                                                            />
                                                        } label="Land surveyor"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study electronical-ICT">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.eict}
                                                                onChange={e => this.setState({ eict: !this.state.eict })}
                                                                name="Electronical-ICT"
                                                                color="primary"
                                                            />
                                                        } label="Electronical-ICT"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study electronical-ICT">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.etech}
                                                                onChange={e => this.setState({ etech: !this.state.etech })}
                                                                name="Electrotechnics"
                                                                color="primary"
                                                            />
                                                        } label="Electrotechnics"
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Card variant="outlined" style={{ marginBottom: "10px" }}>
                                <CardContent>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <TextField error={!isWebsite(this.state.website)}
                                            fullWidth label="Website (Must be valid url, http included, or empty)" name="website" size="small" variant="outlined"
                                            onChange={e => this.setState({ website: e.target.value })}
                                            value={this.state.website}
                                            disabled={this.state.disabled}
                                            style={{ maxWidth: "500px", marginTop: "20px" }}
                                        />
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <TextField error={this.state.short_desc.length === 0}
                                            fullWidth label="Short description" size="small" variant="outlined"
                                            onChange={e => this.setState({ short_desc: e.target.value })}
                                            value={this.state.short_desc}
                                            disabled={this.state.disabled}
                                            multiline
                                            inputProps={{ maxLength: 1750 }}
                                            rows={12}
                                        />
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <TextField error={this.state.functiebeschrijving.length === 0}
                                            fullWidth label="Job description" name="Description" size="small" variant="outlined"
                                            onChange={e => this.setState({ functiebeschrijving: e.target.value })}
                                            value={this.state.functiebeschrijving}
                                            disabled={this.state.disabled}
                                            multiline
                                            inputProps={{ maxLength: 1750 }}
                                            rows={15}
                                        />
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <TextField error={this.state.profiel.length === 0}
                                            fullWidth label="Profile" size="small" variant="outlined"
                                            onChange={e => this.setState({ profiel: e.target.value })}
                                            value={this.state.profiel}
                                            disabled={this.state.disabled}
                                            multiline
                                            inputProps={{ maxLength: 1750 }}
                                            rows={15}
                                        />
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <TextField error={this.state.aanbod.length === 0}
                                            fullWidth label="What do we offer" size="small" variant="outlined"
                                            onChange={e => this.setState({ aanbod: e.target.value })}
                                            value={this.state.aanbod}
                                            disabled={this.state.disabled}
                                            multiline
                                            inputProps={{ maxLength: 1750 }}
                                            rows={15}
                                        />
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <TextField
                                            fullWidth label="Our client" size="small" variant="outlined"
                                            onChange={e => this.setState({ client: e.target.value })}
                                            value={this.state.client}
                                            disabled={this.state.disabled}
                                            multiline
                                            inputProps={{ maxLength: 1750 }}
                                            rows={15}
                                        />
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <TextField error={this.state.interesse.length === 0}
                                            fullWidth label="Interested?" size="small" variant="outlined"
                                            onChange={e => this.setState({ interesse: e.target.value })}
                                            value={this.state.interesse}
                                            disabled={this.state.disabled}
                                            multiline
                                            inputProps={{ maxLength: 1750 }}
                                            rows={15}
                                        />
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <TextField
                                            fullWidth label="Location" size="small" variant="outlined"
                                            onChange={e => this.setState({ locatie: e.target.value })}
                                            value={this.state.locatie}
                                            disabled={this.state.disabled}
                                            multiline
                                            inputProps={{ maxLength: 100 }}
                                            rows={5}
                                        />
                                    </Typography>

                                    <Divider variant="middle" style={{ marginTop: "30px", marginBottom: "20px" }} />
                                </CardContent>
                                <CardActions>
                                    <Grid container justify="center">
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Upload new pdf" style={{}}>
                                                <input type="file" accept="application/pdf" id="inp" onChange={e => {
                                                    let filereader = new FileReader();
                                                    filereader.addEventListener("load", listener.bind(this));
                                                    filereader.readAsDataURL(e.target.files[0])
                                                }
                                                } />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button variant="contained" color="primary" onClick={this.create} disabled={this.state.title.length === 0 || this.state.short_desc.length === 0 || this.state.functiebeschrijving.length === 0 || this.state.profiel.length === 0 || this.state.aanbod.length === 0 || this.state.interesse.length === 0} style={{ marginLeft: "70%", maxWidth: "100%", marginBottom: "10px", marginRight: "20px" }}>
                                                Create vacancy
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden xlUp>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 120px)" }} justify="center">
                        <Grid item xs={10} style={{ marginTop: "30px" }}>
                            <Grid item xs={12} style={{ marginTop: "10px" }}>
                                <Card variant="outlined" style={{ marginBottom: "10px" }}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={3} />
                                            <Grid item xs={6} style={{ textAlign: "center", marginTop: "20px" }}>
                                                <Typography variant="h3" component="h2" style={{}}>
                                                    <TextField error={this.state.title.length === 0} autoFocus
                                                        fullWidth label="Vacancy title" size="small" variant="outlined"
                                                        onChange={e => this.setState({ title: e.target.value })}
                                                        value={this.state.title}
                                                        disabled={this.state.disabled}
                                                        inputProps={{ maxLength: 100 }}
                                                        style={{ maxWidth: "600px" }}
                                                    />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} />
                                            <Grid item xs={12}>
                                                <Tooltip title="Make this page visible on our website">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.is_visible}
                                                                onChange={e => this.setState({ is_visible: !this.state.is_visible })}
                                                                name="visible"
                                                                color="primary"
                                                            />
                                                        } label="visible"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Make this vacancy for an internship or a job">
                                                    <FormControl >
                                                        <InputLabel>Vacancy type</InputLabel>
                                                        <Select
                                                            value={this.state.soort}
                                                            onChange={(e) => this.setState({ soort: e.target.value })}
                                                        >
                                                            <MenuItem value={"job"}>Job</MenuItem>
                                                            <MenuItem value={"stage"}>Internship</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Tooltip>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography>
                                                    Looking for:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Tooltip title="Students who study computer science">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.infor}
                                                                onChange={e => this.setState({ infor: !this.state.infor })}
                                                                name="Computer science"
                                                                color="primary"
                                                            />
                                                        } label="Computer science"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study electromechanics">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.elektromech}
                                                                onChange={e => this.setState({ elektromech: !this.state.elektromech })}
                                                                name="Electromechanics"
                                                                color="primary"
                                                            />
                                                        } label="Electromechanics"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study chemistry">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.chem}
                                                                onChange={e => this.setState({ chem: !this.state.chem })}
                                                                name="Chemistry"
                                                                color="primary"
                                                            />
                                                        } label="Chemistry"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study structural engineering">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.bouw}
                                                                onChange={e => this.setState({ bouw: !this.state.bouw })}
                                                                name="Structural engineering"
                                                                color="primary"
                                                            />
                                                        } label="Structural engineering"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study automation">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.auto}
                                                                onChange={e => this.setState({ auto: !this.state.auto })}
                                                                name="Automation"
                                                                color="primary"
                                                            />
                                                        } label="Automation"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study Land surveyor">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.land}
                                                                onChange={e => this.setState({ land: !this.state.land })}
                                                                name="Land surveyor"
                                                                color="primary"
                                                            />
                                                        } label="Land surveyor"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study electronical-ICT">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.eict}
                                                                onChange={e => this.setState({ eict: !this.state.eict })}
                                                                name="Electronical-ICT"
                                                                color="primary"
                                                            />
                                                        } label="Electronical-ICT"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study electronical-ICT">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.etech}
                                                                onChange={e => this.setState({ etech: !this.state.etech })}
                                                                name="Electrotechnics"
                                                                color="primary"
                                                            />
                                                        } label="Electrotechnics"
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Card variant="outlined" style={{ marginBottom: "10px" }}>
                                <CardContent>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <TextField error={!isWebsite(this.state.website)}
                                            fullWidth label="Website (Must be valid url, http included, or empty)" name="website" size="small" variant="outlined"
                                            onChange={e => this.setState({ website: e.target.value })}
                                            value={this.state.website}
                                            disabled={this.state.disabled}
                                            style={{ maxWidth: "500px", marginTop: "20px" }}
                                        />
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <TextField error={this.state.short_desc.length === 0}
                                            fullWidth label="Short description" size="small" variant="outlined"
                                            onChange={e => this.setState({ short_desc: e.target.value })}
                                            value={this.state.short_desc}
                                            disabled={this.state.disabled}
                                            multiline
                                            inputProps={{ maxLength: 1750 }}
                                            rows={12}
                                        />
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <TextField error={this.state.functiebeschrijving.length === 0}
                                            fullWidth label="Job description" name="Description" size="small" variant="outlined"
                                            onChange={e => this.setState({ functiebeschrijving: e.target.value })}
                                            value={this.state.functiebeschrijving}
                                            disabled={this.state.disabled}
                                            multiline
                                            inputProps={{ maxLength: 1750 }}
                                            rows={15}
                                        />
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <TextField error={this.state.profiel.length === 0}
                                            fullWidth label="Profile" size="small" variant="outlined"
                                            onChange={e => this.setState({ profiel: e.target.value })}
                                            value={this.state.profiel}
                                            disabled={this.state.disabled}
                                            multiline
                                            inputProps={{ maxLength: 1750 }}
                                            rows={15}
                                        />
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <TextField error={this.state.aanbod.length === 0}
                                            fullWidth label="What do we offer" size="small" variant="outlined"
                                            onChange={e => this.setState({ aanbod: e.target.value })}
                                            value={this.state.aanbod}
                                            disabled={this.state.disabled}
                                            multiline
                                            inputProps={{ maxLength: 1750 }}
                                            rows={15}
                                        />
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <TextField
                                            fullWidth label="Our client" size="small" variant="outlined"
                                            onChange={e => this.setState({ client: e.target.value })}
                                            value={this.state.client}
                                            disabled={this.state.disabled}
                                            multiline
                                            inputProps={{ maxLength: 1750 }}
                                            rows={15}
                                        />
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <TextField error={this.state.interesse.length === 0}
                                            fullWidth label="Interested?" size="small" variant="outlined"
                                            onChange={e => this.setState({ interesse: e.target.value })}
                                            value={this.state.interesse}
                                            disabled={this.state.disabled}
                                            multiline
                                            inputProps={{ maxLength: 1750 }}
                                            rows={15}
                                        />
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <TextField
                                            fullWidth label="Location" size="small" variant="outlined"
                                            onChange={e => this.setState({ locatie: e.target.value })}
                                            value={this.state.locatie}
                                            disabled={this.state.disabled}
                                            multiline
                                            inputProps={{ maxLength: 100 }}
                                            rows={5}
                                        />
                                    </Typography>
                                    <Divider variant="middle" style={{ marginTop: "30px", marginBottom: "20px" }} />
                                </CardContent>
                                <CardActions>
                                    <Grid container justify="center">
                                        <Grid item xs={4}>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Tooltip title="Upload new pdf">
                                                <input style={{ marginRight: "50px" }} type="file" accept="application/pdf" id="inp" onChange={e => {
                                                    let filereader = new FileReader();
                                                    filereader.addEventListener("load", listener.bind(this));
                                                    filereader.readAsDataURL(e.target.files[0])
                                                }
                                                } />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="contained" color="primary" onClick={this.create} disabled={this.state.title.length === 0 || this.state.short_desc.length === 0 || this.state.functiebeschrijving.length === 0 || this.state.profiel.length === 0 || this.state.aanbod.length === 0 || this.state.interesse.length === 0} style={{ marginLeft: "70%", maxWidth: "100%", marginBottom: "10px", marginRight: "20px", marginTop: "30px" }}>
                                                Create vacancy
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>

            </div >
        );
    }
}

export default CreateVacature;
