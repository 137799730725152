import React from "react";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Typography } from "@material-ui/core";

class Lost extends React.Component {
    render() {
        return (
            <div>
                <Hidden lgDown>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 150px)" }} justify="center">
                        <Grid variant="outlined" item xs={6} style={{ marginTop: "7%", textAlign: "center" }}>

                            <Typography color="textSecondary">
                                <b><i>"Not all those who wander are lost"</i></b>

                            </Typography>
                            <Typography color="textSecondary" style={{ marginLeft: "20%" }}>
                                - J.R.R. Tolkien
                            </Typography>
                            <Typography color="textSecondary" >
                                But you are definitely lost...
                            </Typography>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden xlUp>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 150px)" }} justify="center">
                        <Grid variant="outlined" item xs={6} style={{ marginTop: "7%", textAlign: "center" }}>

                            <Typography color="textSecondary">
                                <b><i>"Not all those who wander are lost"</i></b>

                            </Typography>
                            <Typography color="textSecondary" style={{ marginLeft: "20%" }}>
                                - J.R.R. Tolkien
                            </Typography>
                            <Typography color="textSecondary" >
                                But you are definitely lost...
                            </Typography>
                        </Grid>
                    </Grid>
                </Hidden>
            </div>
        );
    }
}

export default Lost;
