import React from "react";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';

class Manage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pk: props.par.pk
        }
    }

    render() {
        return (
            <div>
                <div style={{ minHeight: "calc(100vh - 80px - 150px)" }}>
                    <Grid container spacing={12}>
                        <Grid item xs={2} />
                        <Grid item xs={10} style={{ marginTop: "20px" }}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Typography color="textPrimary">My company</Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ marginTop: "50px", fontSize: "25px", textAlign: "center" }}>
                                <b>My company control panel</b>
                            </Typography>
                        </Grid>
                        <Grid iten xs={12} style={{ marginLeft: "10%", marginTop: "20px", marginRight: "10%" }}>
                            <Card variant="outlined" >
                                <CardContent>
                                    <Grid item xs={2} />
                                    <Grid item xs={10} style={{ marginTop: "20px", marginLeft: "20px" }}>
                                        <Link href={"/company/companies/" + this.state.pk} style={{ fontSize: "16px" }}>
                                            See my company page
                                        </Link>
                                    </Grid>
                                    <Grid item xs={2} />
                                    <Grid item xs={10} style={{ marginTop: "10px", marginLeft: "20px" }}>
                                        <Link href={"/company/companies/" + this.state.pk + "/edit"} style={{ fontSize: "16px" }}>
                                            Edit my company page
                                        </Link>
                                    </Grid>
                                    <Grid item xs={2} />
                                    <Grid item xs={10} style={{ marginTop: "10px", marginLeft: "20px" }}>
                                        <Link href={"/company/companies/" + this.state.pk + "/vacatures"} style={{ fontSize: "16px" }}>
                                            Manage my vacancies
                                    </Link>
                                    </Grid>
                                    <Grid item xs={2} />
                                    <Grid item xs={10} style={{ marginTop: "10px", marginLeft: "20px" }}>
                                        <Link href={"/company/companies/" + this.state.pk + "/edit/personal"} style={{ fontSize: "16px" }}>
                                            Edit personal information
                                    </Link>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default Manage;
