import React from "react";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { getUrl } from "../shared/AuthLogic";
import { refresh } from "../shared/AuthLogic";

import {
    Link
} from "react-router-dom";

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mail: "",
            mail2: "",
            invalid: false,
            error: "",
            made: false,
            disabled: false,
            
        };
        this.handleChange = this;
    }

    create = (refreshed=false) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.mail) && this.state.mail.toLowerCase() === this.state.mail2.toLowerCase()) {
            this.setState({ invalid: false, error: "", disabled: true });
            fetch(getUrl() + 'jobbeurs/company/register/', {
                method: "Post",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "account": { "email": this.state.mail.toLowerCase() } })
            })
                .then(res => {
                    if (res.status === 201) {
                        this.setState({ made: true });
                    } else if (res.status === 400) {
                        this.setState({ invalid: true, error: "A user with this email already exists already exists", disabled: false });
                    } else if (res.status === 401) {
                        if (!refreshed) {
                            if (refresh()) {
                                this.create(true);
                            } else {
                                alert("We cannot refresh your token, please refresh or log out and log back in to manually refresh it");
                            }  
                        } else {
                            alert("We cannot refresh your token, please refresh or log out and log back in to manually refresh it");
                        }
                    } else {
                        console.log(res);
                        alert("Something went wrong, " + res.status + ", if this keeps occurring please contact ict@hermesgent.be");
                    }
                }, reason => {
                    this.setState({ invalid: true, error: "Something went wrong, " + JSON.stringify(reason) + ", if this keeps occurring please contact ict@hermesgent.be", disabled: false });
                })
        } else if (this.state.mail.toLowerCase() !== this.state.mail2.toLowerCase()) {
            this.setState({ invalid: true, error: "Both email addresses do no match!", disabled: false });
        } else {
            this.setState({ invalid: true, error: "You have entered an invalid email!", disabled: false });
        }
    }

    render() {
        let errorMessage;
        if (this.state.invalid) {
            errorMessage = <Typography style={{ color: "red" }}>{this.state.error}</Typography>;
        } else {
            errorMessage = <br />;
        }
        let page;
        if (!this.state.made) {
            page = <div>
                <Hidden mdDown>
                    <div style={{ minHeight: "calc(100vh - 80px - 150px)" }}>
                        <Grid container spacing={12} justify="center">
                            <Grid item xs={1} />
                            <Grid item xs={5} style={{ marginTop: "50px" }}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link color="inherit" to="/admin">
                                        Admin
                                    </Link>
                                    <Typography color="textPrimary">Add company</Typography>
                                </Breadcrumbs>
                            </Grid>
                            <Grid item xs={6} />
                            <Grid item xs={10} style={{ marginTop: "50px" }}>
                                <Typography variant="h6" component="h2" style={{ textAlign: "center" }}>
                                    Add company
                                </Typography>
                                <br />
                                <Card variant="outlined" style={{ maxWidth: "400px", marginLeft: "auto", marginRight: "auto", display: "block" }}>
                                    <CardContent >
                                        <Typography gutterBottom>
                                            Email address
                                        </Typography>
                                        <TextField error={this.state.invalid} autoFocus
                                            fullWidth label="Email" name="email" size="small" variant="outlined"
                                            onChange={e => this.setState({ mail: e.target.value })}
                                            value={this.state.mail}
                                            style={{ maxWidth: "400px" }}
                                            disabled={this.state.disabled}
                                        />
                                        <br />
                                        <Typography gutterBottom>
                                            Confirm email address
                                        </Typography>
                                        <TextField
                                            error={this.state.invalid}
                                            fullWidth
                                            label="Confirm email"
                                            name="mail2"
                                            size="small"
                                            variant="outlined"
                                            onChange={e => this.setState({ mail2: e.target.value })}
                                            value={this.state.mail2}
                                            style={{ maxWidth: "400px" }}
                                            disabled={this.state.disabled}
                                        />
                                        {errorMessage}
                                    </CardContent>
                                    <CardActions>
                                        <Grid container spacing={12} justify="center">
                                            <Grid item xs={6}>
                                                <Button disabled={this.state.disabled} size="small" variant="contained" style={{ backgroundColor: "green", color: "white" }} onClick={ () => this.create()}>Create company</Button>
                                            </Grid>
                                        </Grid>

                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Hidden>
                <Hidden lgUp>
                    <div style={{ minHeight: "calc(100vh - 80px - 260px)" }}>
                        <Grid container spacing={12} justify="center">
                            <Grid item xs={1} />
                            <Grid item xs={5} style={{ marginTop: "50px" }}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link color="inherit" to="/admin">
                                        Admin
                                    </Link>
                                    <Typography color="textPrimary">Add company</Typography>
                                </Breadcrumbs>
                            </Grid>
                            <Grid item xs={6} />
                            <Grid item xs={10} style={{ marginTop: "50px" }}>
                                <Typography variant="h6" component="h2" style={{ textAlign: "center" }}>
                                    Add company
                                </Typography>
                                <br />
                                <Card variant="outlined" style={{ maxWidth: "400px", marginLeft: "auto", marginRight: "auto", display: "block" }}>
                                    <CardContent >
                                        <Typography gutterBottom>
                                            Email address
                                        </Typography>
                                        <TextField error={this.state.invalid} autoFocus
                                            fullWidth label="Email" name="email" size="small" variant="outlined"
                                            onChange={e => this.setState({ mail: e.target.value })}
                                            value={this.state.mail}
                                            style={{ maxWidth: "400px" }}
                                            disabled={this.state.disabled}
                                        />
                                        <br />
                                        <Typography gutterBottom>
                                            Confirm email address
                                        </Typography>
                                        <TextField
                                            error={this.state.invalid}
                                            fullWidth
                                            label="Confirm email"
                                            name="mail2"
                                            size="small"
                                            variant="outlined"
                                            onChange={e => this.setState({ mail2: e.target.value })}
                                            value={this.state.mail2}
                                            style={{ maxWidth: "400px" }}
                                            disabled={this.state.disabled}
                                        />
                                        {errorMessage}
                                    </CardContent>
                                    <CardActions>
                                        <Grid container spacing={12} justify="center">
                                            <Grid item xs={6}>
                                                <Button disabled={this.state.disabled} size="small" variant="contained" style={{ backgroundColor: "green", color: "white" }} onClick={() => this.create()}>Create company</Button>
                                            </Grid>
                                        </Grid>

                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Hidden>
            </div >;
        } else {
            page = <div>
                <Hidden mdDown>
                    <div style={{ minHeight: "calc(100vh - 80px - 150px)" }}>
                        <Grid container spacing={12} justify="center">
                            <Grid item xs={1} />
                            <Grid item xs={5} style={{ marginTop: "50px" }}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link color="inherit" to="/admin">
                                        Admin
                                    </Link>
                                    <Typography color="textPrimary">Add company</Typography>
                                </Breadcrumbs>
                            </Grid>
                            <Grid item xs={6} />
                            <Grid item xs={12} style={{ marginTop: "50px" }}>
                                <Typography variant="h6" component="h2" style={{ textAlign: "center" }}>
                                    Add company
                                </Typography>
                                <br />
                                <Card variant="outlined" style={{ maxWidth: "400px", marginLeft: "auto", marginRight: "auto", display: "block" }}>
                                    <CardContent>
                                        <Typography gutterBottom>
                                            The account has been created. An email had been sent to {this.state.mail} with further instructions to login!
                                    </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Grid container spacing={12} justify="center">
                                            <Grid item xs={6}>
                                                <Button size="small" variant="contained" style={{ backgroundColor: "green", color: "white" }} onClick={() => { document.location.reload() }}>Add another</Button>
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Hidden>
                <Hidden lgUp>
                    <div style={{ minHeight: "calc(100vh - 80px - 260px)" }}>
                        <Grid container spacing={12} justify="center">
                            <Grid item xs={1} />
                            <Grid item xs={5} style={{ marginTop: "50px" }}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link color="inherit" to="/admin">
                                        Admin
                                    </Link>
                                    <Typography color="textPrimary">Add company</Typography>
                                </Breadcrumbs>
                            </Grid>
                            <Grid item xs={6} />
                            <Grid item xs={12} style={{ marginTop: "50px" }}>
                                <Typography variant="h6" component="h2" style={{ textAlign: "center" }}>
                                    Add company
                                </Typography>
                                <br />
                                <Card variant="outlined" style={{ maxWidth: "400px", marginLeft: "auto", marginRight: "auto", display: "block" }}>
                                    <CardContent>
                                        <Typography gutterBottom>
                                            The account has been created. An email had been sent to {this.state.mail} with further instructions to login!
                                    </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Grid container spacing={12} justify="center">
                                            <Grid item xs={6}>
                                                <Button size="small" variant="contained" style={{ backgroundColor: "green", color: "white" }} onClick={() => { document.location.reload() }}>Add another</Button>
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Hidden>
            </div >;
        }
        return (
            page
        )
    }
}

export default Admin;
