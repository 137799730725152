import React from "react";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Card from "@material-ui/core/Card";
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Lost from "../shared/NoMatch";
import { put, get } from "../shared/AuthLogic";

function isMail(mail) {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail);
}

function isTelephoneNumber(number) {
    return /^\d{9}\d?\d?\d?\d?\d?$/.test(number);
}

class Personal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            straat: "",
            nummer: "",
            postcode: "",
            land: "",
            stad: "",
            BTW: "",
            bedrijfsnaam: "",
            telefoonnummer: "",
            mail: "",
            fname: "",
            lname: "",
            cmail: "",
            refreshed: false,
            exists: true,
            extra: "",
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
            pk: props.par.pk,
        }
        this.getInformation();
    }

    getInformation = () => {
        if (this.state.user !== null) {
            get("jobbeurs/companies/" + this.state.pk + "/personal/", true)
                .then(json => {
                    if (json !== false) {
                        this.setState({
                            straat: json.straat,
                            nummer: json.nummer,
                            postcode: json.postcode,
                            land: json.land,
                            stad: json.stad,
                            BTW: json.BTW,
                            bedrijfsnaam: json.bedrijfsnaam,
                            telefoonnummer: json.telefoonnummer,
                            mail: json.billingmail,
                            fname: json.fname,
                            lname: json.lname,
                            cmail: json.cmail,
                            extra: json.extra,
                            exists: true,
                        });
                    } else {
                        this.setState({ exists: false });
                    }
                });
        }

    }

    update = () => {
        this.setState({ failed: false, invalid: false, disabled: true });
        let obj = {
            straat: this.state.straat,
            nummer: this.state.nummer,
            postcode: this.state.postcode,
            land: this.state.land,
            stad: this.state.stad,
            BTW: this.state.BTW,
            bedrijfsnaam: this.state.bedrijfsnaam,
            telefoonnummer: this.state.telefoonnummer,
            billingmail: this.state.mail,
            fname: this.state.fname,
            lname: this.state.lname,
            cmail: this.state.cmail,
            extra: this.state.extra,
        };
        put("jobbeurs/companies/" + this.state.pk + "/personal/update/", obj, true)
            .then(success => {
                if (success) {
                    document.location.href = "/company/companies/" + this.state.pk + "/manage/";
                } else {
                    alert("Something went wrong, if this keeps occurring please contact ict@hermesgent.be");
                }
            });
    }

    render() {
        if (!this.state.exists) {
            return <Lost />;
        }
        return (
            <div>
                <Hidden lgDown>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 120px)" }} justify="center">
                        <Grid item xs={6} style={{ marginTop: "30px" }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link color="inherit" href={"/company/companies/" + this.state.pk + "/manage/"}>
                                            My company
                                            </Link>
                                        <Typography color="textPrimary">Edit my personal information</Typography>
                                    </Breadcrumbs>
                                </Grid>
                            </Grid>
                            <Card variant="outlined" style={{ marginBottom: "10px", marginTop: "10px" }}>
                                <CardContent>
                                    <Grid container>
                                    <Grid item xs={6} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>First name</b>
                                            </Typography>
                                            <TextField error={this.state.fname.length === 0}
                                                fullWidth label="First name" size="small" variant="outlined"
                                                onChange={e => this.setState({ fname: e.target.value })}
                                                value={this.state.fname}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 100 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Last name</b>
                                            </Typography>
                                            <TextField error={this.state.lname.length === 0}
                                                fullWidth label="Last name" size="small" variant="outlined"
                                                onChange={e => this.setState({ lname: e.target.value })}
                                                value={this.state.lname}
                                                disabled={this.state.disabled}
                                                style={{ width: "90%" }}
                                                inputProps={{ maxLength: 100 }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Contact email</b>
                                            </Typography>
                                            <TextField error={!isMail(this.state.cmail)}
                                                fullWidth label="Contact email" size="small" variant="outlined"
                                                onChange={e => this.setState({ cmail: e.target.value })}
                                                value={this.state.cmail}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 100 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>

                                        <Grid item xs={6} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Telephone number</b>
                                            </Typography>
                                            <TextField error={!isTelephoneNumber(this.state.telefoonnummer)}
                                                fullWidth label="Telephone number" size="small" variant="outlined"
                                                onChange={e => this.setState({ telefoonnummer: e.target.value })}
                                                value={this.state.telefoonnummer}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 15 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                                            <Typography variant="h4">
                                                <b>Billing information</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Company name</b>
                                            </Typography>
                                            <TextField error={this.state.bedrijfsnaam.length === 0}
                                                fullWidth label="Company name" name="Company name" size="small" variant="outlined"
                                                onChange={e => this.setState({ bedrijfsnaam: e.target.value })}
                                                value={this.state.bedrijfsnaam}
                                                disabled={this.state.disabled}
                                                style={{ width: "90%" }}
                                                inputProps={{ maxLength: 100 }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>VAT Number</b>
                                            </Typography>
                                            <TextField error={this.state.BTW.length === 0}
                                                fullWidth label="VAT number e.g. BE 0000.000.000" name="Street" size="small" variant="outlined"
                                                onChange={e => this.setState({ BTW: e.target.value })}
                                                value={this.state.BTW}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 30 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>

                                        <Grid item xs={6} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Billing-Email</b>
                                            </Typography>
                                            <TextField error={!isMail(this.state.mail)}
                                                fullWidth label="Billing email" size="small" variant="outlined"
                                                onChange={e => this.setState({ mail: e.target.value })}
                                                value={this.state.mail}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 100 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>

                                        <Grid item xs={9} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Street</b>
                                            </Typography>
                                            <TextField error={this.state.straat.length === 0}
                                                fullWidth label="Street" size="small" variant="outlined"
                                                onChange={e => this.setState({ straat: e.target.value })}
                                                value={this.state.straat}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 500 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>
                                        <Grid item xs={3} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Number (and extra's e.g. postbox)</b>
                                            </Typography>
                                            <TextField error={this.state.nummer === null || this.state.nummer.length === 0}
                                                fullWidth label="Number" size="small" variant="outlined"
                                                onChange={e => this.setState({ nummer: e.target.value })}
                                                value={this.state.nummer}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 100 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>

                                        <Grid item xs={2} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Postal code</b>
                                            </Typography>
                                            <TextField error={this.state.postcode === null || isNaN(this.state.postcode) || this.state.nummer.postcode === 0}
                                                fullWidth label="Postal code" size="small" variant="outlined"
                                                onChange={e => this.setState({ postcode: e.target.value })}
                                                value={this.state.postcode}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 30 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>
                                        <Grid item xs={5} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>City</b>
                                            </Typography>
                                            <TextField error={this.state.stad.length === 0}
                                                fullWidth label="City" size="small" variant="outlined"
                                                onChange={e => this.setState({ stad: e.target.value })}
                                                value={this.state.stad}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 100 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>
                                        <Grid item xs={5} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Country</b>
                                            </Typography>
                                            <TextField error={this.state.land.length === 0}
                                                fullWidth label="Country" size="small" variant="outlined"
                                                onChange={e => this.setState({ land: e.target.value })}
                                                value={this.state.land}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 100 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Extra information</b>
                                            </Typography>
                                            <TextField
                                                fullWidth label="Extra" size="small" variant="outlined"
                                                onChange={e => this.setState({ extra: e.target.value })}
                                                value={this.state.extra}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 500 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Card style={{ marginBottom: "10px", textAlign: "center" }}>
                                        <CardContent >
                                            <Typography >
                                                <b>This information will only be used so our recruitments can contact you and for administrative purposes</b>
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" onClick={this.update}
                                        disabled={this.state.land.length === 0 || this.state.stad.length === 0 || (this.state.postcode === null || isNaN(this.state.postcode) || this.state.postcode.length === 0) || (this.state.nummer === null || this.state.nummer.length === 0) || this.state.straat.length === 0 || !isTelephoneNumber(this.state.telefoonnummer) || !isMail(this.state.mail) || this.state.BTW.length === 0 || this.state.bedrijfsnaam.length === 0 || this.state.lname.length === 0 || this.state.fname.length === 0 || !isMail(this.state.cmail)}
                                        style={{ marginLeft: "70%", maxWidth: "20%", marginBottom: "10px" }}>
                                        Confirm
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden xlUp>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 150px)" }} justify="center">
                        <Grid item xs={10} style={{ marginTop: "30px" }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link color="inherit" href={"/company/companies/" + this.state.pk + "/manage/"}>
                                            My company
                                            </Link>
                                        <Typography color="textPrimary">Edit my personal information</Typography>
                                    </Breadcrumbs>
                                </Grid>
                            </Grid>
                            <Card variant="outlined" style={{ marginBottom: "10px", marginTop: "10px" }}>
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                                            <Typography variant="h4">
                                                <b>Contact person information</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{ marginBottom: "30px" }}>
                                        <Typography style={{ marginBottom: "5px" }}>
                                                <b>First name</b>
                                            </Typography>
                                            <TextField error={this.state.fname.length === 0}
                                                fullWidth label="First name" size="small" variant="outlined"
                                                onChange={e => this.setState({ fname: e.target.value })}
                                                value={this.state.fname}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 100 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Last name</b>
                                            </Typography>
                                            <TextField error={this.state.lname.length === 0}
                                                fullWidth label="Last name" size="small" variant="outlined"
                                                onChange={e => this.setState({ lname: e.target.value })}
                                                value={this.state.lname}
                                                disabled={this.state.disabled}
                                                style={{ width: "90%" }}
                                                inputProps={{ maxLength: 100 }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Contact email</b>
                                            </Typography>
                                            <TextField error={!isMail(this.state.cmail)}
                                                fullWidth label="Contact email" size="small" variant="outlined"
                                                onChange={e => this.setState({ cmail: e.target.value })}
                                                value={this.state.cmail}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 100 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>

                                        <Grid item xs={6} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Telephone number</b>
                                            </Typography>
                                            <TextField error={!isTelephoneNumber(this.state.telefoonnummer)}
                                                fullWidth label="Telephone number" size="small" variant="outlined"
                                                onChange={e => this.setState({ telefoonnummer: e.target.value })}
                                                value={this.state.telefoonnummer}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 15 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                                            <Typography variant="h4">
                                                <b>Billing information</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Company name</b>
                                            </Typography>
                                            <TextField error={this.state.bedrijfsnaam.length === 0}
                                                fullWidth label="Company name" name="Company name" size="small" variant="outlined"
                                                onChange={e => this.setState({ bedrijfsnaam: e.target.value })}
                                                value={this.state.bedrijfsnaam}
                                                disabled={this.state.disabled}
                                                style={{ width: "90%" }}
                                                inputProps={{ maxLength: 100 }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>VAT Number</b>
                                            </Typography>
                                            <TextField error={this.state.BTW.length === 0}
                                                fullWidth label="VAT number e.g. BE 0000.000.000" name="Street" size="small" variant="outlined"
                                                onChange={e => this.setState({ BTW: e.target.value })}
                                                value={this.state.BTW}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 30 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>

                                        <Grid item xs={6} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Billing-Email</b>
                                            </Typography>
                                            <TextField error={!isMail(this.state.mail)}
                                                fullWidth label="Billing email" size="small" variant="outlined"
                                                onChange={e => this.setState({ mail: e.target.value })}
                                                value={this.state.mail}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 100 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>

                                        <Grid item xs={9} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Street</b>
                                            </Typography>
                                            <TextField error={this.state.straat.length === 0}
                                                fullWidth label="Street" size="small" variant="outlined"
                                                onChange={e => this.setState({ straat: e.target.value })}
                                                value={this.state.straat}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 500 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>
                                        <Grid item xs={3} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Number (and extra's e.g. postbox)</b>
                                            </Typography>
                                            <TextField error={this.state.nummer === null || this.state.nummer.length === 0}
                                                fullWidth label="Number" size="small" variant="outlined"
                                                onChange={e => this.setState({ nummer: e.target.value })}
                                                value={this.state.nummer}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 100 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>

                                        <Grid item xs={2} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Postal code</b>
                                            </Typography>
                                            <TextField error={this.state.postcode === null || isNaN(this.state.postcode) || this.state.nummer.postcode === 0}
                                                fullWidth label="Postal code" size="small" variant="outlined"
                                                onChange={e => this.setState({ postcode: e.target.value })}
                                                value={this.state.postcode}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 30 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>
                                        <Grid item xs={5} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>City</b>
                                            </Typography>
                                            <TextField error={this.state.stad.length === 0}
                                                fullWidth label="City" size="small" variant="outlined"
                                                onChange={e => this.setState({ stad: e.target.value })}
                                                value={this.state.stad}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 100 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>
                                        <Grid item xs={5} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Country</b>
                                            </Typography>
                                            <TextField error={this.state.land.length === 0}
                                                fullWidth label="Country" size="small" variant="outlined"
                                                onChange={e => this.setState({ land: e.target.value })}
                                                value={this.state.land}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 100 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ marginBottom: "30px" }}>
                                            <Typography style={{ marginBottom: "5px" }}>
                                                <b>Extra information</b>
                                            </Typography>
                                            <TextField
                                                fullWidth label="Extra" size="small" variant="outlined"
                                                onChange={e => this.setState({ extra: e.target.value })}
                                                value={this.state.extra}
                                                disabled={this.state.disabled}
                                                inputProps={{ maxLength: 500 }}
                                                style={{ width: "90%" }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Card style={{ marginBottom: "10px", textAlign: "center" }}>
                                        <CardContent >
                                            <Typography >
                                                <b>This information will only be used so our recruitments can contact you and for administrative purposes</b>
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" onClick={this.update}
                                        disabled={this.state.land.length === 0 || this.state.stad.length === 0 || (this.state.postcode === null || isNaN(this.state.postcode) || this.state.postcode.length === 0) || (this.state.nummer === null || this.state.nummer.length === 0) || this.state.straat.length === 0 || !isTelephoneNumber(this.state.telefoonnummer) || !isMail(this.state.mail) || this.state.BTW.length === 0 || this.state.bedrijfsnaam.length === 0 || this.state.lname.length === 0 || this.state.fname.length === 0 || !isMail(this.state.cmail)}
                                        style={{ marginLeft: "70%", maxWidth: "20%", marginBottom: "10px" }}>
                                        Confirm
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>
            </div >
        )
    }
}

export default Personal;