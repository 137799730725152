import React from "react";
import RecruitmentR from '../static/images/RecruitmentR_white.png'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import {
    Link
} from "react-router-dom";

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl1: null,
            anchorEl2: null,
            loggedIn: localStorage.getItem("token") ? true : false,
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
        }
        if (localStorage.getItem("user")) {
            this.setState({user: JSON.parse(localStorage.getItem("user"))});
        }
    }

    handleClose = () => {
        this.setState({ anchorEl1: null, anchorEl2: null });
    };

    logOut = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        document.location.href="/home";
    }

    render() {
        let button;
        let mobileButton;
        let adminButton = <div></div>;
        let adminMobileButton = <div></div>;
        let companyButton;
        let MobileCompanyButton;
        if (this.state.loggedIn) {
            button = <Button color="inherit" href="/logout">Log out</Button>
            mobileButton = <MenuItem onClick={this.handleClose} component={Link} to="/logout">Log out</MenuItem>
            if (localStorage.getItem("user")) {
                let user = JSON.parse(localStorage.getItem("user"));
                if (user.isRecruitment) {
                    adminButton = <Button color="inherit" href="/admin">Admin</Button>
                    adminMobileButton = <MenuItem onClick={this.handleClose} component={Link} to="/admin">Admin</MenuItem>
                }
            }
            if (this.state.user !== null && "company_pk" in this.state.user) {
                companyButton = <Button color="inherit" href={"/company/companies/" + this.state.user.company_pk + "/manage/"}>My company</Button>
                MobileCompanyButton = <MenuItem onClick={this.handleClose} component={Link} to={"/company/companies/" + this.state.user.company_pk + "/manage/"}>My company</MenuItem>
            }
        } else {
            button = <Button color="inherit" href="/login">Log in</Button>
            mobileButton = <MenuItem onClick={this.handleClose} component={Link} to="/login">Log in</MenuItem>
        }
        return (
            <AppBar position="static" style={{ background: '#292929', height: '80px' }}>
                <Toolbar>
                    <Grid container spacing={12} >
                        <Grid item xs={2} style={{ textAlign: "right" }}>
                            <a href="/home">
                                <img
                                    src={RecruitmentR}
                                    width="70"
                                    height="50"
                                    alt="Hermes Recruitment logo"
                                    style={{ marginTop: '15px'}}
                                />
                            </a>
                        </Grid>
                        <Hidden mdDown>
                            <Grid item xs={8} style={{ marginTop: '25px', textAlign: "right", color: 'white' }} >
                                <Button color="inherit" href="/company">
                                    Home
                                </Button>
                                <Button color="inherit" href="/company/services">Services</Button>
                                <Button color="inherit" href="/company/jobstageevent">Job & Internship Fair</Button>
                                <Button color="inherit" href="/company/companies">Companies</Button>
                                <Button color="inherit" href="/company/about">About us</Button>
                                {companyButton}
                                {adminButton}
                            </Grid>
                            <Grid item xs={1} style={{ marginTop: '25px', textAlign: "right", color: '#white' }}>
                                {button}
                                
                            </Grid>
                        </Hidden>
                        <Hidden lgUp>
                            <Grid item xs={9} style={{ marginTop: '25px', textAlign: "right" }}>
                                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.setState({ anchorEl1: e.currentTarget })}>
                                    <MenuIcon style={{ fill: "white" }} />
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={this.state.anchorEl1}
                                    keepMounted
                                    open={Boolean(this.state.anchorEl1)}
                                    onClose={this.handleClose}
                                >
                                    <MenuItem onClick={this.handleClose} component={Link} to="/company">Home</MenuItem>
                                    <MenuItem onClick={this.handleClose} component={Link} to="/company/services">Services</MenuItem>
                                    <MenuItem onClick={this.handleClose} component={Link} to="/company/jobstageevent">Job & Internship Fair</MenuItem>
                                    <MenuItem onClick={this.handleClose} component={Link} to="/company/companies">Companies</MenuItem>
                                    <MenuItem onClick={this.handleClose} component={Link} to="/company/about">About us</MenuItem>
                                    {MobileCompanyButton}
                                    {adminMobileButton}
                                    <Divider />
                                    {mobileButton}
                                </Menu>
                            </Grid>
                            
                        </Hidden>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }
}

export default NavBar;
