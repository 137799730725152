import React from "react";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Hidden from '@material-ui/core/Hidden';
import RecruitmentR from '../static/images/RecruitmentR.png'
import { withRouter } from "react-router";
import {
    Link
} from "react-router-dom";
import { getUrl } from "../shared/AuthLogic";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pass1: "",
            pass2: "",
            invalid: false,
            token: this.props.match.params.token,
            error: "",
            success: false

        };
    }

    resetPass = (e) => {
        if (this.state.pass1 === this.state.pass2) {
            this.setState({ invalid: false });
            fetch(getUrl() + 'auth/reset/password-reset/confirm/', {
                method: "Post",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: this.state.token,
                    password: this.state.pass1
                })
            })
                .then(res => {
                    if (res.status === 200) {
                        this.setState({ success: true });
                        return true;
                    } else if (res.status === 400) {
                        return res.json();
                    } else if (res.status === 404) {
                        this.setState({ invalid: true, error: "Your used link is invalid. It has either expired (lifetime 24h) or you've used a wrong link!" });
                        return true;
                    }
                }, reason => {
                    this.setState({ invalid: true, error: "Something went wrong, " + JSON.stringify(reason) + ", if this keeps occurring please contact ict@hermesgent.be" });
                })
                .then(json => {
                    if (json !== true) {
                        let error_message = "";
                        for (e in json.password) {
                            if (error_message === "") {
                                error_message = "- " + json.password[e];
                            } else {
                                error_message += "\n- " + json.password[e];
                            }
                        }
                        this.setState({ invalid: true, error: error_message});
                    }
                });
        } else {
            this.setState({ invalid: true, error: "Your passwords do not match" });
        }
    }

    render() {
        let errorMessage;
        let page;
        if (this.state.success) {
            page = <div>
                <Hidden lgDown>
                    <Grid container spacing={12} style={{ height: "calc(100vh - 80px - 120px)" }}>
                        <Grid item xs={5} />
                        <Grid item xs={2} style={{ textAlign: "center" }}>
                            <br />
                            <br />
                            <br />
                            <img
                                src={RecruitmentR}
                                display="block"
                                width="80"
                                height="60"
                                alt="Hermes Recruitment logo"
                                style={{ marginTop: '15px', marginLeft: "auto", marginRight: "auto", display: "block" }}
                            />
                            <br />
                            <Typography variant="h6" component="h2" >
                                Change password
                        </Typography>
                            <br />
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom>
                                        Your password has been changed successfully!
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={12} >
                                        <Grid item xs={2} />
                                        <Grid item xs={8}>
                                            <Button component={Link} to="/home" style={{ color: "blue", fontSize: 11 }}>
                                                Take me back home
                                        </Button>
                                        </Grid>
                                        <Grid item xs={2} />
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={5} />
                    </Grid>
                </Hidden>
                <Hidden xlUp>
                    <Grid container spacing={12} style={{ height: "calc(100vh - 80px - 150px)", minHeight: "400px", marginTop: "50px" }} justify="center">
                        <Grid item xs={8} style={{ textAlign: "center" }}>
                            <img
                                src={RecruitmentR}
                                width="80"
                                height="60"
                                alt="Hermes Recruitment logo"
                                style={{ marginTop: '15px', marginLeft: "auto", marginRight: "auto", display: "block" }}
                            />
                            <br />
                            <Typography variant="h6" component="h2" style={{ textAlign: "center" }}>
                                Change password
                        </Typography>
                            <br />
                            <Card variant="outlined" style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "300px" }}>
                                <CardContent>
                                    <Typography gutterBottom>
                                        Your password has been changed successfully!
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={12} >
                                        <Grid item xs={2} />
                                        <Grid item xs={8}>
                                            <Button component={Link} to="/home" style={{ color: "blue", fontSize: 11 }}>
                                                Take me back home
                                            </Button>
                                        </Grid>
                                        <Grid item xs={2}>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>
            </div >;
        } else {
            if (this.state.invalid) {
                errorMessage = <Typography style={{ color: "red", whiteSpace: "pre-line" }}>{this.state.error}</Typography>;
            } else {
                errorMessage = <br />;
            }
            page = <div>
                <Hidden lgDown>
                    <Grid container spacing={12} style={{ height: "calc(100vh - 80px - 120px)" }} justify="center">
                        <Grid item xs={2} style={{ marginTop: "50px" }}>
                            <img
                                src={RecruitmentR}
                                width="80"
                                height="60"
                                alt="Hermes Recruitment logo"
                                style={{ marginTop: '15px', marginLeft: "auto", marginRight: "auto", display: "block" }}
                            />
                            <Typography variant="h6" component="h2" style={{ textAlign: "center" }}>
                                Change password
                        </Typography>
                            <br />
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom>
                                        Password
                                </Typography>
                                    <TextField error={this.state.invalid} autoFocus
                                        fullWidth label="New password" name="new password" size="small" variant="outlined"
                                        onChange={e => this.setState({ pass1: e.target.value })}
                                        value={this.state.pass1}
                                        type="password"
                                    />
                                    <br />
                                    <Typography gutterBottom>
                                        Confirm password
                                </Typography>
                                    <TextField
                                        error={this.state.invalid}
                                        fullWidth
                                        label="Confirm password"
                                        name="confirm password"
                                        size="small"
                                        type="password"
                                        variant="outlined"
                                        onChange={e => this.setState({ pass2: e.target.value })}
                                        value={this.state.pass2}
                                    />
                                    {errorMessage}
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={12} justify="center">
                                        <Grid item xs={5}>
                                            <Button size="small" variant="contained" style={{ backgroundColor: "green", color: "white" }} onClick={this.resetPass}>Change password</Button>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden xlUp>
                    <Grid container spacing={12} style={{ height: "calc(100vh - 80px - 150px)", minHeight: "450px", marginTop: "50px" }} justify="center">
                        <Grid item xs={8}>
                            <img
                                src={RecruitmentR}
                                width="80"
                                height="60"
                                alt="Hermes Recruitment logo"
                                style={{ marginTop: '15px', marginLeft: "auto", marginRight: "auto", display: "block" }}
                            />
                            <br />
                            <Typography variant="h6" component="h2" style={{ textAlign: "center" }} >
                                Change password
                        </Typography>
                            <br />
                            <Card variant="outlined" style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "300px" }}>
                                <CardContent>
                                    <Typography gutterBottom>
                                        Password
                                </Typography>
                                    <TextField error={this.state.invalid} autoFocus
                                        fullWidth label="New password" name="new password" size="small" variant="outlined"
                                        onChange={e => this.setState({ pass1: e.target.value })}
                                        value={this.state.pass1}
                                        type="password"
                                    />
                                    <br />
                                    <Typography gutterBottom>
                                        Confirm password
                                </Typography>
                                    <TextField
                                        error={this.state.invalid}
                                        fullWidth
                                        label="Confirm password"
                                        name="confirm password"
                                        size="small"
                                        type="password"
                                        variant="outlined"
                                        onChange={e => this.setState({ pass2: e.target.value })}
                                        value={this.state.pass2}
                                    />
                                    {errorMessage}
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={12} justify="center">
                                        <Grid item xs={5}>
                                            <Button size="small" variant="contained" style={{ backgroundColor: "green", color: "white" }} onClick={this.resetPass}>Change password</Button>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>
            </div >
        }

        return (
            <div>{page}</div>
        )
    }
}

export default withRouter(LoginPage);