import React from "react";
import Jobbeurs_image from '../static/images/jobbeurs_home1_resize.jpg'
import Jobbeurs_image_2 from '../static/images/jobbeurs_home2_resize.jpg'
import Jobbeurs_image_3 from '../static/images/jobbeurs_home3_resize.jpg'
import effect from '../static/images/effect.jpg'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Carousel from 'react-material-ui-carousel'
import { Paper, Typography } from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/DescriptionRounded'
import AccessibilityIcon from '@material-ui/icons/AccessibilityNewRounded';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindowsRounded';
import MoreHorizIcon from '@material-ui/icons/MoreHorizRounded';
import Leeuw from "../static/leeuw.mp3"

function Item(props) {
    return (
        <div>
            <Hidden mdDown>
                <Grid container style={{ marginTop: "50px", marginBottom: "50px" }}>
                    <Grid item xs={4}>
                        <div style={{ backgroundColor: "", width: "100%", height: "100%" }}>
                            <Grid container justify="center">
                                <Grid item xs={12}>
                                    <Paper elevation={1} style={{ marginTop: "50px", backgroundColor: "#3D3D3D", color: "white", height: "300px", width: "1000%", marginLeft: "-400%" }}>
                                    </Paper>
                                </Grid>
                                <Grid item xs={7} style={{ marginTop: "-290px" }}>
                                    <Typography style={{ color: "white", fontSize: "40px" }}>
                                        <b>{props.item.Title}</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} style={{ marginTop: "-180px" }}>
                                    <Typography style={{ color: "white", fontSize: "16px" }}>
                                        {props.item.Message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{ marginTop: "-60px", marginLeft: "300px" }}>
                                    <Button href={props.item.url} variant="contained">
                                        {props.item.ButtonText}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <img src={props.item.image} alt="" style={{ width: "100%", height: "100%", marginTop: "10px"}} />
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden lgUp>
                <Grid container style={{ marginTop: "50px" }}>
                    <Grid item xs={12}>
                        <img src={props.item.image} alt="" style={{ width: "75%", height: "100%", display: "block", marginLeft: "auto", marginRight: "auto"}} />
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ backgroundColor: "", width: "100%", height: "100%", marginTop: "10px"}}>
                            <Grid container justify="center">
                                <Grid item xs={12}>
                                    <Paper elevation={1} style={{ marginTop: "50px", backgroundColor: "#3D3D3D", color: "white", height: "300px", width: "1000%", marginLeft: "-400%" }}>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: "-290px", textAlign: "center"}}>
                                    <Typography style={{ color: "white", fontSize: "40px" }}>
                                        <b>{props.item.Title}</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: "-180px", textAlign: "center", marginLeft: "20px", marginRight: "20px" }}>
                                    <Typography style={{ color: "white", fontSize: "16px" }}>
                                        {props.item.Message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{ marginTop: "-60px", textAlign: "center", marginLeft: "20px", marginRight: "20px" }}>
                                    <Button href={props.item.url} variant="contained" style={{}}>
                                        {props.item.ButtonText}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                </Grid>
            </Hidden>
        </div>
    )
}

class Home extends React.Component {

    items = [
        {
            image: Jobbeurs_image_2,
            Title: "Hermes Recruitment",
            Message: "Hermes Recruitment is the recruiting department of the student association Hermes Gent npo. We support students in their last bachelor or master year in the start of their professional career.",
            ButtonText: "Learn more",
            url: "/company/about/"
        },
        {
            image: Jobbeurs_image,
            Title: "Annual Job & Internship Fair",
            Message: "Annual Job & Internship Fair which will take place on the 24th of February 2022",
            ButtonText: "Take me there",
            url: "/company/jobstageevent"
        },
        {
            image: Jobbeurs_image_3,
            Title: "Services",
            Message: "Hermes recruitment offers you plenty of services such as a resume guide, the ability to publish job/internship vacancies, social media posts (Instagram, Facebook, LinkedIn) and so much more.",
            ButtonText: "Learn more",
            url: "/company/services/"
        }
    ]

    audio = new Audio(Leeuw);

    constructor(props) {
        super(props);
        if (localStorage.getItem("Julien")) {
            this.audio.play();
            this.items = [
                {
                    image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/VlaamseStrijdvlag.svg/1200px-VlaamseStrijdvlag.svg.png",
                    Title: "Hermes Recruitment",
                    Message: "Hermes Recruitment is the recruiting department of the student association Hermes Gent npo. We support students in their last bachelor or master year in the start of their professional career.",
                    ButtonText: "Dit is vlaemsche grond",
                    url: "https://www.youtube.com/watch?v=ilots2URyKM"
                }
            ];
        }
    }


    render() {
        return (
            <div>
                <Hidden lgDown>
                    <div style={{ position: 'relative', backgroundImage: `url(${effect})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", backgroundAttachment: "fixed" }}>

                        <Grid container spacing={12} style={{ minHeight: "calc(100vh -80px - 150px)" }}>
                            <Grid item xs={12} style={{ marginTop: "" }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <div style={{ width: "100%" }}>
                                            <Carousel animation="slide" timeout={750} indicators={false} interval={8000}>
                                                {
                                                    this.items.map((item, i) => <Item key={i} item={item} />)
                                                }
                                            </Carousel>
                                        </div>
                                    </Grid>
                                    <div style={{ width: "100%", height: "150px" }}>

                                    </div>

                                    <div style={{ backgroundColor: "#3D3D3D", width: "100%", height: "300px" }}>
                                        <Grid container justify="center">
                                            <Grid item xs={3} >
                                                <Typography style={{ textAlign: "center", color: "white", marginTop: "20px", fontSize: "25px" }}>
                                                    <b>What do we offer</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container justify="center" alignItems="center" style={{ textAlign: "center", color: "white", marginTop: "40px" }}>

                                            <Grid item xs={3}>
                                                <DescriptionIcon style={{ width: "100px", height: "100px" }} />
                                                <Typography style={{ fontSize: "20px" }}>
                                                    Access to our collected resumes
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={3}>
                                                <AccessibilityIcon style={{ width: "100px", height: "100px" }} />
                                                <Typography style={{ fontSize: "20px" }}>
                                                    Event with hundreds of students
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={3}>
                                                <DesktopWindowsIcon style={{ width: "100px", height: "100px" }} />
                                                <Typography style={{ fontSize: "18px" }}>
                                                    Add job/internship publications
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <MoreHorizIcon style={{ width: "100px", height: "100px" }} />
                                                <Typography style={{ fontSize: "18px" }}>
                                                    So much more
                                                </Typography>
                                            </Grid>

                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Hidden>
                <Hidden xlUp>
                    <div style={{ position: 'relative', backgroundImage: `url(${effect})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", backgroundAttachment: "fixed" }}>
                        <Grid container spacing={12} style={{ minHeight: "calc(100vh -150px - 80px)" }}>
                            <Grid item xs={12} style={{ marginTop: "" }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <div style={{ width: "100%",}}>
                                            <Carousel animation="slide" timeout={750} indicators={false} interval={8000}>
                                                {
                                                    this.items.map((item, i) => <Item key={i} item={item} />)
                                                }
                                            </Carousel>
                                        </div>
                                    </Grid>
                                    <div style={{ width: "100%", height: "150px"}}>

                                    </div>

                                    <div style={{ backgroundColor: "#3D3D3D", width: "100%", height: "300px" }}>
                                        <Grid container justify="center">
                                            <Grid item xs={3} >
                                                <Typography style={{ textAlign: "center", color: "white", marginTop: "20px", fontSize: "20px" }}>
                                                    <b>What do we offer</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container justify="center" alignItems="center" style={{ textAlign: "center", color: "white", marginTop: "40px" }}>

                                            <Grid item xs={3}>
                                                <DescriptionIcon style={{ width: "70px", height: "70px" }} />
                                                <Typography style={{ fontSize: "12px" }}>
                                                    Access to our collected resumes
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={3}>
                                                <AccessibilityIcon style={{ width: "70px", height: "70px" }} />
                                                <Typography style={{ fontSize: "12px" }}>
                                                    Event with hundreds of students
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={3}>
                                                <DesktopWindowsIcon style={{ width: "70px", height: "70px" }} />
                                                <Typography style={{ fontSize: "12px" }}>
                                                    Add job/internship publications
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <MoreHorizIcon style={{ width: "70px", height: "70px" }} />
                                                <Typography style={{ fontSize: "12px" }}>
                                                    So much more
                                                </Typography>
                                            </Grid>

                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Hidden>
            </div >
        );
    }
}

export default Home;
