import React from "react";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Lost from "../shared/NoMatch";
import Checkbox from '@material-ui/core/Checkbox';
import { get } from "../shared/AuthLogic";

class Welcome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            read: false,
            exists: true,
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
        };
        get("jobbeurs/users/me/", true)
            .then(json => {
                if (json !== false) {
                    if (json.isStudent) {
                        this.setState({ exists: false });
                    }
                }
            });
    }

    render() {
        if (!this.state.exists) {
            return <Lost />;
        }
        return (
            <div>
                <div style={{ position: 'relative', backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", backgroundAttachment: "fixed" }}>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 150px)" }}>
                        <Grid item xs={12} style={{}}>
                            <Grid container>
                                <div style={{ backgroundColor: "", width: "100%", minHeight: "500px", marginBottom: "20px"}}>
                                    <Grid container justify="center" >
                                        <Grid item xs={12} style={{ textAlign: "center" }}>
                                            <Typography style={{ fontSize: "25px", marginTop: "3%" }}>
                                                Welcome
                                                </Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ textAlign: "center" }}>
                                            <Typography style={{ fontSize: "16px", marginTop: "3%" }}>
                                                An email has been sent to your registered email address with instructions to change your password.
                                                </Typography>
                                            <Typography style={{ fontSize: "11px", marginBottom: "20px" }}>
                                                <b>The included link will be valid for 24 hours. If you are too late you can always use the "reset password" feature on the login page!</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ textAlign: "center" }}>
                                            <Typography style={{ fontSize: "16px", marginTop: "3%" }}>
                                                You can manage your company in the company management panel which you will be able to access through "My company" in the navbar.
                                                </Typography>
                                            <Typography style={{ fontSize: "16px" }}>
                                                In the company management panel you will be able to:
                                                    <br /><br />
                                                <ul>
                                                    <li>Inspect and edit your company page (the page that visitors will see)</li>
                                                    <li>Manage your vacancies</li>
                                                    <li>Edit your personal information</li>
                                                </ul>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ textAlign: "center" }}>
                                            <Typography style={{ fontSize: "16px", marginTop: "3%" }}>
                                                Please, if possible, fill in/correct the <b>personal information</b> page so our recruitments can provide an even better service.
                                                </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ backgroundColor: "#3D3D3D", width: "100%", height: "211px" }}>
                                    <Grid container justify="center">
                                        <Grid item xs={12} >
                                            <div style={{ color: "white", marginTop: "30px", fontSize: "15px" }}>
                                                <Typography style={{ textAlign: "center", fontSize: "15px" }}>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={this.state.read}
                                                        onChange={() => this.setState({ read: !this.state.read })}
                                                    />
                                                        I have read this page
                                                    </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={2} style={{ marginTop: "20px", maxWidth: "220px", textAlign: "center" }}>
                                            <Button href={"/company/companies/" + this.state.user.company_pk + "/manage/"} variant="contained" disabled={!this.state.read} color="primary" style={{ marginLeft: "auto", marginRight: "auto", display: "block" }}>
                                                Take me to my company
                                                </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

            </div>
        )
    }
}

export default Welcome;