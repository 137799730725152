import React from "react";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import {logout} from "./AuthLogic";
import {
    Link
} from "react-router-dom";

class LogoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: localStorage.getItem("token") ? true : false,
        }
        if (this.state.loggedIn) {
            logout().then(suc => {
                if (suc) {
                    this.setState({loggedIn: false})
                    document.location.href="/home";
                } else {
                    alert("Something went wrong, refreshing. If this keeps happening please contact ict@hermesgent.be");
                    document.location.reload();
                }
            });
        }
    }

    render() {

        let message;
        if (this.state.loggedIn) {
            message = <Typography gutterBottom>Logging out...</Typography>
        } else {
            message = <Typography gutterBottom>Logged out successfully! Returning home</Typography>
        }
        let page;
        if (!localStorage.getItem('token')) {
            page = <div>
            <Hidden lgDown>
                <Grid container spacing={12} style={{ height: "calc(100vh - 80px - 120px)" }} justify="center">
                    <Grid item xs={2} style={{ textAlign: "center", marginTop: "100px" }}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography gutterBottom>
                                    You are not logged in
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Grid container spacing={12} justify="center" >
                                    <Grid item xs={4}>
                                        <Button component={Link} to="/home" style={{ color: "blue", fontSize: 11 }}>
                                            Take me back home
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button component={Link} to="/login" style={{ color: "blue", fontSize: 11, marginTop: "10px" }}>
                                            Log in
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden xlUp>
                <Grid container spacing={12} style={{ height: "calc(100vh - 80px - 150px)", minHeight: "400px", marginTop: "50px" }} justify="center">
                    <Grid item xs={8} style={{ textAlign: "center" }}>
                        <Card variant="outlined" style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "300px", marginTop: "100px" }}>
                            <CardContent>
                                <Typography gutterBottom>
                                    You are not logged in
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Grid container spacing={12} justify="center">
                                    <Grid item xs={4}>
                                        <Button component={Link} to="/home" style={{ color: "blue", fontSize: 11}}>
                                            Take me back home
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button component={Link} to="/login" style={{ color: "blue", fontSize: 11, marginTop: "10px"}}>
                                            Log in
                                        </Button>
                                    </Grid>
                                </Grid>

                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Hidden>
        </div >;
        } else {
            page = <div>
            <Hidden lgDown>
                <Grid container spacing={12} style={{ height: "calc(100vh - 80px - 120px)" }} justify="center">
                    <Grid item xs={2} style={{ textAlign: "center", marginTop: "100px" }}>
                        <Card variant="outlined">
                            <CardContent>
                                {message}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden xlUp>
                <Grid container spacing={12} style={{ height: "calc(100vh - 80px - 150px)", minHeight: "400px", marginTop: "50px" }} justify="center">
                    <Grid item xs={8} style={{ textAlign: "center" }}>
                        <Card variant="outlined" style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "300px", marginTop: "100px" }}>
                            <CardContent>
                                {message}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Hidden>
        </div >;
        }
        return (
            page
        )
    }
}

export default LogoutPage;