import React from "react";
import CompanyList from "./CompanyList";

class Companies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isStudent: props.props.isStudent,
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
        }
    }


    render() {
        return (
            <div>
                <CompanyList url={"jobbeurs/companies/"} authenticate={false} isStudent={this.state.isStudent}/>
            </div>
        );
    }
}

export default Companies;
