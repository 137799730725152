import React from "react";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import CompanyList from "../shared/CompanyList";

import {
    Link
} from "react-router-dom";

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
        }
    }


    render() {
        return (
            <div>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={5} style={{ marginTop: "50px" }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" to="/admin">
                                Admin
                                    </Link>
                            <Typography color="textPrimary">Search companies</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={12}>
                        <CompanyList url={"jobbeurs/companies/all/"} admin={true} authenticate={true} />
                    </Grid>
                </Grid>

            </div>
        );
    }
}

export default Search;
