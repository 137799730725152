import React from "react";
import Recruitment1 from '../static/images/Arnaud.jpg'
import Recruitment2 from '../static/images/jelle.png'
import Ict from '../static/images/ict.png'
import Ict2 from '../static/images/rein.jpg'
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Typography } from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutlineRounded';
import PhoneIcon from '@material-ui/icons/PhoneAndroidRounded';

class About extends React.Component {
    
    render() {
        return (
            <div>
                <Hidden mdDown>
                    <div style={{ position: 'relative', backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", backgroundAttachment: "fixed" }}>
                        <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 150px)" }}>
                            <Grid item xs={12} style={{}}>
                                <Grid container>
                                    <div style={{ backgroundColor: "", width: "100%", height: "500px" }}>
                                        <Grid container justify="center" >
                                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                                <Typography style={{ fontSize: "25px", marginTop: "3%" }}>
                                                    Meet our team
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{ marginTop: "50px" }}>
                                                <div style={{ width: "250px", height: "250px", marginLeft: "auto", marginRight: "auto", display: "block" }}>
                                                    <img src={Recruitment1} alt="" style={{ width: "200px", height: "200px" }} />
                                                    <Typography style={{ fontSize: "18px", textAlign: "center", marginLeft: "-50px" }}>
                                                        <b>Arnaud Vandenberghe</b>
                                                        <br />
                                                            Recruitment
                                                        </Typography>
                                                    <Typography style={{ fontSize: "14px" }}>
                                                        <a href="mailto:recruitment@hermesgent.be" style={{ color: "black" }}><MailOutlineIcon fontSize="small" /> recruitment@hermesgent.be</a>
                                                    </Typography>
                                                    <Typography style={{ fontSize: "14px" }}>
                                                        <PhoneIcon fontSize="small" /> +32 471 77 93 75
                                                        </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={2} style={{ marginTop: "50px" }}>
                                                <div style={{ width: "250px", height: "250px", marginLeft: "auto", marginRight: "auto", display: "block" }}>
                                                    <img src={Recruitment2} alt="" style={{ width: "200px", height: "200px" }} />
                                                    <Typography style={{ fontSize: "18px", textAlign: "center", marginLeft: "-50px" }}>
                                                        <b>Jelle Peelman</b>
                                                        <br />
                                                            Recruitment
                                                        </Typography>
                                                    <Typography style={{ fontSize: "14px" }}>
                                                        <a href="mailto:recruitment@hermesgent.be" style={{ color: "black" }}><MailOutlineIcon fontSize="small" /> recruitment@hermesgent.be</a>
                                                    </Typography>
                                                    <Typography style={{ fontSize: "14px" }}>
                                                        <PhoneIcon fontSize="small" /> +32 495 54 60 53
                                                        </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={2} style={{ marginTop: "50px" }}>
                                                <div style={{ width: "250px", height: "250px", marginLeft: "auto", marginRight: "auto", display: "block" }}>
                                                    <img src={Ict} alt="" style={{ width: "200px", height: "200px" }} />
                                                    <Typography style={{ fontSize: "18px", textAlign: "center", marginLeft: "-50px" }}>
                                                        <b>Robrecht Vandersteen</b>
                                                        <br />
                                                        Website maintainer
                                                        </Typography>
                                                    <Typography style={{ fontSize: "14px" }}>
                                                        <a href="mailto:ict@hermesgent.be" style={{ color: "black" }}><MailOutlineIcon fontSize="small" /> ict@hermesgent.be</a>
                                                    </Typography>
                                                    <Typography style={{ fontSize: "14px" }}>
                                                        <PhoneIcon fontSize="small" /> +32 478 36 90 80
                                                        </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={2} style={{ marginTop: "50px" }}>
                                                <div style={{ width: "250px", height: "250px", marginLeft: "auto", marginRight: "auto", display: "block" }}>
                                                    <img src={Ict2} alt="" style={{ width: "200px", height: "200px" }} />
                                                    <Typography style={{ fontSize: "18px", textAlign: "center", marginLeft: "-50px" }}>
                                                        <b>Rein Vanbelleghem</b>
                                                        <br />
                                                        Website maintainer
                                                        </Typography>
                                                    <Typography style={{ fontSize: "14px" }}>
                                                        <a href="mailto:ict@hermesgent.be" style={{ color: "black" }}><MailOutlineIcon fontSize="small" /> ict@hermesgent.be</a>
                                                    </Typography>
                                                    <Typography style={{ fontSize: "14px" }}>
                                                        <PhoneIcon fontSize="small" /> +32 494 10 28 95
                                                        </Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>


                                    <div style={{ backgroundColor: "#3D3D3D", width: "100%", height: "248px" }}>
                                        <Grid container justify="center">
                                            <Grid item xs={4} >
                                                <Typography style={{ textAlign: "center", color: "white", marginTop: "20px", fontSize: "30px" }}>
                                                    <b>About Hermes Recruitment</b>
                                                </Typography>
                                                <div style={{ color: "white", marginTop: "20px", fontSize: "15px" }}>
                                                    <Typography style={{ textAlign: "center", fontSize: "15px" }}>
                                                        Hermes Recruitment is the recruiting department of the student association Hermes Gent npo. We support students in their last bachelor or master year in the start of their professional career.
                                                    </Typography>
                                                </div>
                                            </Grid>

                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Hidden>
                <Hidden lgUp>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 150px)" }}>
                        <Grid item xs={12} style={{}}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container justify="center" >
                                        <Grid item xs={12} style={{ textAlign: "center" }}>
                                            <Typography style={{ fontSize: "25px", marginTop: "3%" }}>
                                                Meet our team
                                                </Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{ marginTop: "50px" }}>
                                            <div style={{ width: "250px", height: "250px", marginLeft: "auto", marginRight: "auto", display: "block" }}>
                                                <img src={Recruitment1} alt="" style={{ width: "200px", height: "200px" }} />
                                                <Typography style={{ fontSize: "18px", textAlign: "center", marginLeft: "-50px" }}>
                                                    <b>Arnaud Vandenberghe</b>
                                                    <br />
                                                            Recruitment
                                                        </Typography>
                                                <Typography style={{ fontSize: "14px" }}>
                                                    <a href="mailto:recruitment@hermesgent.be" style={{ color: "black" }}><MailOutlineIcon fontSize="small" /> recruitment@hermesgent.be</a>
                                                </Typography>
                                                <Typography style={{ fontSize: "14px" }}>
                                                    <PhoneIcon fontSize="small" /> +32 471 77 93 75
                                                        </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} style={{ marginTop: "50px" }}>
                                            <div style={{ width: "250px", height: "250px", marginLeft: "auto", marginRight: "auto", display: "block" }}>
                                                <img src={Recruitment2} alt="" style={{ width: "200px", height: "200px" }} />
                                                <Typography style={{ fontSize: "18px", textAlign: "center", marginLeft: "-50px" }}>
                                                    <b>Thibaut Staelens</b>
                                                    <br />
                                                            Recruitment
                                                        </Typography>
                                                <Typography style={{ fontSize: "14px" }}>
                                                    <a href="mailto:recruitment@hermesgent.be" style={{ color: "black" }}><MailOutlineIcon fontSize="small" /> recruitment@hermesgent.be</a>
                                                </Typography>
                                                <Typography style={{ fontSize: "14px" }}>
                                                    <PhoneIcon fontSize="small" /> +32 492 64 20 14
                                                        </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={4} style={{ marginTop: "50px" }}>
                                            <div style={{ width: "250px", height: "250px", marginLeft: "auto", marginRight: "auto", display: "block" }}>
                                                <img src={Ict} alt="" style={{ width: "200px", height: "200px" }} />
                                                <Typography style={{ fontSize: "18px", textAlign: "center", marginLeft: "-50px" }}>
                                                    <b>Robrecht Vandersteen</b>
                                                    <br />
                                                        Website maintainer
                                                        </Typography>
                                                <Typography style={{ fontSize: "14px" }}>
                                                    <a href="mailto:ict@hermesgent.be" style={{ color: "black" }}><MailOutlineIcon fontSize="small" /> ict@hermesgent.be</a>
                                                </Typography>
                                                <Typography style={{ fontSize: "14px" }}>
                                                    <PhoneIcon fontSize="small" /> +32 478 36 90 80
                                                        </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{ backgroundColor: "#3D3D3D", width: "100%", height: "250px", marginTop: "80px"}}>
                                        <Grid container justify="center">
                                            <Grid item xs={10} >
                                                <Typography style={{ textAlign: "center", color: "white", marginTop: "20px", fontSize: "30px" }}>
                                                    <b>About Hermes Recruitment</b>
                                                </Typography>
                                                <div style={{ color: "white", marginTop: "20px", fontSize: "15px" }}>
                                                    <Typography style={{ textAlign: "center", fontSize: "15px" }}>
                                                        Hermes Recruitment is the recruiting department of the student association Hermes Gent npo. We support students in their last bachelor or master year in the start of their professional career.
                                                    </Typography>
                                                </div>
                                            </Grid>

                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
            </div >
        );
    }
}

export default About;
