import React from "react";
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Card from "@material-ui/core/Card";
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Lost from "../shared/NoMatch";
import { get, put } from "../shared/AuthLogic";

function listener(e) {
    this.setState({ logo: e.target.result, updated_logo: true });
}

function isWebsite(site) {
    if (site.length === 0) {
        return true;
    }
    let expression = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/g;
    return site.match(expression);
}

class EditCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            contact: "You can contact me here",
            desc_long: "This is a long description",
            history: "",
            international: false,
            is_visible: false,
            location: "",
            logo: "",
            updated_logo: false,
            masterproeven: false,
            name: "title",
            website: "https://www.hermesgent.be/",
            pk: props.par.pk,
            elektromech: false,
            infor: false,
            chem: false,
            bouw: false,
            auto: false,
            land: false,
            eict: false,
            etech: false,
            failed: false,
            account: true,
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
            exists: true,
            companyKey: localStorage.getItem("user") && "company_pk" in JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).company_pk : null
        }
        this.getInformation();
    }

    getInformation = () => {
        get("jobbeurs/companies/" + this.state.pk)
            .then(json => {
                if (json !== false) {
                    this.setState({
                        contact: json.contact,
                        desc_long: json.desc_long,
                        history: json.history,
                        international: json.international,
                        is_visible: json.is_visible,
                        location: json.location,
                        logo: json.logo,
                        masterproeven: json.masterproeven,
                        name: json.name,
                        website: json.website,
                        pk: json.pk,
                        elektromech: json.interest.includes("elektromech"),
                        infor: json.interest.includes("infor"),
                        chem: json.interest.includes("chem"),
                        bouw: json.interest.includes("bouw"),
                        auto: json.interest.includes("auto"),
                        land: json.interest.includes("land"),
                        eict: json.interest.includes("eict"),
                        etech: json.interest.includes("etech"),
                        account: json.account !== null,
                        exists: true,
                    });
                } else {
                    this.setState({ exists: false });
                }
            });
    }

    update = () => {
        this.setState({ failed: false, invalid: false, disabled: true });
        let obj = {
            contact: this.state.contact,
            desc_long: this.state.desc_long,
            international: this.state.international,
            is_visible: this.state.is_visible,
            location: this.state.location,
            masterproeven: this.state.masterproeven,
            name: this.state.name,
            website: this.state.website,
        };
        let interests = []
        if (this.state.elektromech) {
            interests.push("elektromech");
        }
        if (this.state.infor) {
            interests.push("infor");
        }
        if (this.state.chem) {
            interests.push("chem");
        }
        if (this.state.bouw) {
            interests.push("bouw");
        }
        if (this.state.auto) {
            interests.push("auto");
        }
        if (this.state.land) {
            interests.push("land");
        }
        if (this.state.eict) {
            interests.push("eict");
        }
        if (this.state.etech) {
            interests.push("etech");
        }
        obj.interest = interests;
        if (this.state.updated_logo) {
            obj.logo = this.state.logo
        }
        console.log(JSON.stringify(obj));
        put("jobbeurs/companies/" + this.state.pk + "/edit/", obj, true)
            .then(success => {
                if (success) {
                    document.location.href = "/company/companies/" + this.state.pk;
                } else {
                    alert("Something went wrong, if this keeps occurring please contact ict@hermesgent.be");
                }
            });
    };

    render() {
        if (!this.state.exists) {
            return <Lost />;
        }
        let admin;
        if (!this.state.account && this.state.user !== null && this.state.user.isRecruitment) {
            admin = <div>
                <Typography style={{ color: "red" }}>
                    This company has no account!
                </Typography>
                <Link href={"/admin/add_company_account/" + this.state.pk}>
                    Make account
                </Link>
            </div >
        }
        return (
            <div>
                <Hidden lgDown>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 120px)" }} justify="center">
                        <Grid item xs={6} style={{ marginTop: "30px" }}>
                            <Grid container spacing={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Link color="inherit" href={"/company/companies/" + this.state.pk + "/manage/"}>
                                                My company
                                            </Link>
                                            <Typography color="textPrimary">Edit my company</Typography>
                                        </Breadcrumbs>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    {admin}
                                </Grid>
                                <Grid item xs={4}>
                                    <div style={{ minHeight: "50px", minWidth: "50px", maxHeight: "150px", maxWidth: "150px", marginTop: '15px', marginBottom: '15px' }}>
                                        <img
                                            src={this.state.logo}
                                            alt="No logo"
                                            style={{ marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", display: "block", maxWidth: "100%", maxHeight: "100%" }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "center", marginTop: "20px" }}>
                                    <Typography variant="h3" component="h2" style={{}}>
                                        <TextField error={this.state.name.length === 0} autoFocus
                                            fullWidth label="Company name" name="Company name" size="small" variant="outlined"
                                            onChange={e => this.setState({ name: e.target.value })}
                                            value={this.state.name}
                                            disabled={this.state.disabled}
                                            inputProps={{ maxLength: 50 }}
                                            style={{ maxWidth: "600px" }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={12}>
                                    <Tooltip title="Change logo">
                                        <input type="file" accept="image/jpeg, image/png, image/gif" id="inp" onChange={e => {
                                            let filereader = new FileReader();
                                            filereader.addEventListener("load", listener.bind(this));
                                            filereader.readAsDataURL(e.target.files[0])
                                        }
                                        } />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField error={!isWebsite(this.state.website)}
                                        fullWidth label="Website (Must be valid url, http included, or empty)" name="website" size="small" variant="outlined"
                                        onChange={e => this.setState({ website: e.target.value })}
                                        value={this.state.website}
                                        disabled={this.state.disabled}
                                        style={{ maxWidth: "500px", marginTop: "20px" }}
                                    />
                                </Grid>


                            </Grid>
                            <Tooltip title="Is your company active internationally">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.international}
                                            onChange={e => this.setState({ international: !this.state.international })}
                                            name="international"
                                            color="primary"
                                        />
                                    } label="international"
                                />
                            </Tooltip>
                            <Tooltip title="Can students who are looking for a place to do their master's thesis contact you">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.masterproeven}
                                            onChange={e => this.setState({ masterproeven: !this.state.masterproeven })}
                                            name="masterproeven"
                                            color="primary"
                                        />
                                    } label="masterproeven"
                                />
                            </Tooltip>
                            <Tooltip title="Make this page visible on our website">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.is_visible}
                                            onChange={e => this.setState({ is_visible: !this.state.is_visible })}
                                            name="visible"
                                            color="primary"
                                        />
                                    } label="visible"
                                />
                            </Tooltip>
                            <Grid item xs={12} style={{ marginTop: "10px" }}>
                                <Card variant="outlined" style={{ marginBottom: "10px" }}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Interested in:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Tooltip title="Students who study computer science">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.infor}
                                                                onChange={e => this.setState({ infor: !this.state.infor })}
                                                                name="Computer science"
                                                                color="primary"
                                                            />
                                                        } label="Computer science"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study electromechanics">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.elektromech}
                                                                onChange={e => this.setState({ elektromech: !this.state.elektromech })}
                                                                name="Electromechanics"
                                                                color="primary"
                                                            />
                                                        } label="Electromechanics"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study chemistry">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.chem}
                                                                onChange={e => this.setState({ chem: !this.state.chem })}
                                                                name="Chemistry"
                                                                color="primary"
                                                            />
                                                        } label="Chemistry"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study structural engineering">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.bouw}
                                                                onChange={e => this.setState({ bouw: !this.state.bouw })}
                                                                name="Structural engineering"
                                                                color="primary"
                                                            />
                                                        } label="Structural engineering"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study automation">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.auto}
                                                                onChange={e => this.setState({ auto: !this.state.auto })}
                                                                name="Automation"
                                                                color="primary"
                                                            />
                                                        } label="Automation"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study Land surveyor">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.land}
                                                                onChange={e => this.setState({ land: !this.state.land })}
                                                                name="Land surveyor"
                                                                color="primary"
                                                            />
                                                        } label="Land surveyor"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study electronical-ICT">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.eict}
                                                                onChange={e => this.setState({ eict: !this.state.eict })}
                                                                name="Electronical-ICT"
                                                                color="primary"
                                                            />
                                                        } label="Electronical-ICT"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study electronical-ICT">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.etech}
                                                                onChange={e => this.setState({ etech: !this.state.etech })}
                                                                name="Electrotechnics"
                                                                color="primary"
                                                            />
                                                        } label="Electrotechnics"
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Card variant="outlined" style={{ marginBottom: "10px" }}>
                                <CardContent>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <TextField error={this.state.desc_long.length === 0}
                                            fullWidth label="Company description" name="Description" size="small" variant="outlined"
                                            onChange={e => this.setState({ desc_long: e.target.value })}
                                            value={this.state.desc_long}
                                            disabled={this.state.disabled}
                                            inputProps={{ maxLength: 5000 }}
                                            multiline
                                            rows={30}
                                        />
                                    </Typography>
                                    <Divider variant="middle" style={{ marginTop: "30px", marginBottom: "20px" }} />
                                    <Grid container spacing={12} style={{ marginTop: "10px" }}>
                                        <Grid item xs={5}>
                                            <Typography style={{ textAlign: "center", marginBottom: "20px", whiteSpace: "pre-line" }}>
                                                <b>
                                                    Contact
                                                </b>
                                            </Typography>
                                            <Typography style={{ marginLeft: "10px" }}>
                                                <TextField
                                                    fullWidth label="Contact info" name="Contact info" size="small" variant="outlined"
                                                    onChange={e => this.setState({ contact: e.target.value })}
                                                    value={this.state.contact}
                                                    disabled={this.state.disabled}
                                                    inputProps={{ maxLength: 500 }}
                                                    multiline
                                                    rows={7}
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} >
                                            <Divider style={{ marginLeft: "auto", marginRight: "auto" }} orientation="vertical" />
                                        </Grid>
                                        <Grid item xs={5} style={{ marginLeft: "-5%" }}>
                                            <Typography style={{ textAlign: "center", marginBottom: "20px", whiteSpace: "pre-line" }}>
                                                <b>
                                                    Location
                                        </b>
                                            </Typography>
                                            <Typography>
                                                <TextField error={this.state.location !== null}
                                                    fullWidth label="Location info" name="Location info" size="small" variant="outlined"
                                                    onChange={e => this.setState({ location: e.target.value })}
                                                    value={this.state.location}
                                                    disabled={this.state.disabled}
                                                    inputProps={{ maxLength: 500 }}
                                                    multiline
                                                    rows={7}
                                                />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" color="primary" onClick={this.update} disabled={!isWebsite(this.state.website) || this.state.desc_long.length === 0 || this.state.name.length === 0 || this.state.name.length > 50 || this.state.desc_long.length > 5000 || (this.state.location !== null && this.state.location.length > 500) || this.state.contact.length > 500} style={{ marginLeft: "70%", maxWidth: "20%", marginBottom: "10px" }}>
                                        Confirm changes
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden xlUp>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 150px)" }} justify="center">
                        <Grid item xs={10} style={{ marginTop: "30px" }}>
                            <Grid container spacing={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Link color="inherit" href={"/company/companies/" + this.state.pk + "/manage/"}>
                                                My company
                                            </Link>
                                            <Typography color="textPrimary">Edit my company</Typography>
                                        </Breadcrumbs>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {admin}
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <div style={{ minHeight: "50px", minWidth: "50px", maxHeight: "150px", maxWidth: "150px", marginTop: '15px', marginBottom: '15px' }}>
                                        <img
                                            src={this.state.logo}
                                            alt="No logo"
                                            style={{ marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", display: "block", maxWidth: "100%", maxHeight: "100%" }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "center", marginTop: "20px" }}>
                                    <Typography variant="h3" component="h2" style={{}}>
                                        <TextField error={this.state.name.length === 0} autoFocus
                                            fullWidth label="Company name" name="Company name" size="small" variant="outlined"
                                            onChange={e => this.setState({ name: e.target.value })}
                                            value={this.state.name}
                                            disabled={this.state.disabled}
                                            inputProps={{ maxLength: 50 }}
                                            style={{ maxWidth: "600px" }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={12}>
                                    <Tooltip title="Change logo">
                                        <input type="file" accept="image/jpeg, image/png, image/gif" id="inp" onChange={e => {
                                            let filereader = new FileReader();
                                            filereader.addEventListener("load", listener.bind(this));
                                            filereader.readAsDataURL(e.target.files[0])
                                        }
                                        } />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField error={!isWebsite(this.state.website)}
                                        fullWidth label="Website (Must be valid url, http included, or empty)" name="website" size="small" variant="outlined"
                                        onChange={e => this.setState({ website: e.target.value })}
                                        value={this.state.website}
                                        disabled={this.state.disabled}
                                        style={{ maxWidth: "500px", marginTop: "20px" }}
                                    />
                                </Grid>

                            </Grid>
                            <Tooltip title="Is your company active internationally">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.international}
                                            onChange={e => this.setState({ international: !this.state.international })}
                                            name="international"
                                            color="primary"
                                        />
                                    } label="international"
                                />
                            </Tooltip>
                            <Tooltip title="Can students who are looking for a place to do their master's thesis contact you">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.masterproeven}
                                            onChange={e => this.setState({ masterproeven: !this.state.masterproeven })}
                                            name="masterproeven"
                                            color="primary"
                                        />
                                    } label="masterproeven"
                                />
                            </Tooltip>
                            <Tooltip title="Make this page visible on our website">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.is_visible}
                                            onChange={e => this.setState({ is_visible: !this.state.is_visible })}
                                            name="visible"
                                            color="primary"
                                        />
                                    } label="visible"
                                />
                            </Tooltip>
                            <Grid item xs={12} style={{ marginTop: "10px" }}>
                                <Card variant="outlined" style={{ marginBottom: "10px" }}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Interested in:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Tooltip title="Students who study computer science">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.infor}
                                                                onChange={e => this.setState({ infor: !this.state.infor })}
                                                                name="Computer science"
                                                                color="primary"
                                                            />
                                                        } label="Computer science"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study electromechanics">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.elektromech}
                                                                onChange={e => this.setState({ elektromech: !this.state.elektromech })}
                                                                name="Electromechanics"
                                                                color="primary"
                                                            />
                                                        } label="Electromechanics"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study chemistry">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.chem}
                                                                onChange={e => this.setState({ chem: !this.state.chem })}
                                                                name="Chemistry"
                                                                color="primary"
                                                            />
                                                        } label="Chemistry"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study structural engineering">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.bouw}
                                                                onChange={e => this.setState({ bouw: !this.state.bouw })}
                                                                name="Structural engineering"
                                                                color="primary"
                                                            />
                                                        } label="Structural engineering"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study automation">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.auto}
                                                                onChange={e => this.setState({ auto: !this.state.auto })}
                                                                name="Automation"
                                                                color="primary"
                                                            />
                                                        } label="Automation"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study Land surveyor">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.land}
                                                                onChange={e => this.setState({ land: !this.state.land })}
                                                                name="Land surveyor"
                                                                color="primary"
                                                            />
                                                        } label="Land surveyor"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study electronical-ICT">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.eict}
                                                                onChange={e => this.setState({ eict: !this.state.eict })}
                                                                name="Electronical-ICT"
                                                                color="primary"
                                                            />
                                                        } label="Electronical-ICT"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Students who study electronical-ICT">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.etech}
                                                                onChange={e => this.setState({ etech: !this.state.etech })}
                                                                name="Electrotechnics"
                                                                color="primary"
                                                            />
                                                        } label="Electrotechnics"
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Card variant="outlined" style={{ marginBottom: "10px" }}>
                                <CardContent>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <TextField error={this.state.desc_long.length === 0}
                                            fullWidth label="Company description" name="Description" size="small" variant="outlined"
                                            onChange={e => this.setState({ desc_long: e.target.value })}
                                            value={this.state.desc_long}
                                            disabled={this.state.disabled}
                                            multiline
                                            inputProps={{ maxLength: 5000 }}
                                            rows={30}
                                        />
                                    </Typography>
                                    <Divider variant="middle" style={{ marginTop: "30px", marginBottom: "20px" }} />
                                    <Grid container spacing={12} style={{ marginTop: "10px" }}>
                                        <Grid item xs={5}>
                                            <Typography style={{ textAlign: "center", marginBottom: "20px", whiteSpace: "pre-line" }}>
                                                <b>
                                                    Contact
                                                </b>
                                            </Typography>
                                            <Typography style={{ marginLeft: "10px" }}>
                                                <TextField
                                                    fullWidth label="Contact info" name="Contact info" size="small" variant="outlined"
                                                    onChange={e => this.setState({ contact: e.target.value })}
                                                    value={this.state.contact}
                                                    disabled={this.state.disabled}
                                                    multiline
                                                    inputProps={{ maxLength: 500 }}
                                                    rows={7}
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} >
                                            <Divider style={{ marginLeft: "auto", marginRight: "auto" }} orientation="vertical" />
                                        </Grid>
                                        <Grid item xs={5} style={{ marginLeft: "-5%" }}>
                                            <Typography style={{ textAlign: "center", marginBottom: "20px", whiteSpace: "pre-line" }}>
                                                <b>
                                                    Location
                                        </b>
                                            </Typography>
                                            <Typography>
                                                <TextField error={this.state.location !== null}
                                                    fullWidth label="Location info" name="Location info" size="small" variant="outlined"
                                                    onChange={e => this.setState({ location: e.target.value })}
                                                    value={this.state.location}
                                                    disabled={this.state.disabled}
                                                    inputProps={{ maxLength: 500 }}
                                                    multiline
                                                    rows={7}
                                                />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" color="primary" onClick={this.update} disabled={!isWebsite(this.state.website) || this.state.desc_long.length === 0 || this.state.name.length === 0 || this.state.name.length > 50 || this.state.desc_long.length > 5000 || (this.state.location !== null && this.state.location.length > 500) || this.state.contact.length > 500} style={{ marginLeft: "50%", maxWidth: "40%", marginBottom: "10px" }}>
                                        Confirm changes
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>
            </div >
        );
    }
}

export default EditCompany;
