import React from "react";
import Jobbeurs_image from '../static/images/jobbeurs_home1_resize.jpg'
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider';

class Event extends React.Component {

    render() {
        return (
            <div>
                <Hidden lgDown>

                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 150px)" }}>
                        <Grid item xs={12} style={{ marginTop: "" }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <div style={{ backgroundColor: "", width: "100%", height: "500px" }}>
                                        <Grid container justify="center" >
                                            <Grid item xs={6} style={{ marginTop: "50px" }}>
                                                <img alt="" src={Jobbeurs_image} style={{ width: "100%", height: "100%" }} />
                                            </Grid>
                                        </Grid>

                                    </div>
                                </Grid>
                                <div style={{ backgroundColor: "#3D3D3D", width: "100%" }}>
                                    <Grid container justify="center">
                                        <Grid item xs={12} >
                                            <Typography style={{ textAlign: "center", color: "white", marginTop: "20px", fontSize: "30px" }}>
                                                <b>Annual Job & Internship Fair</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} >
                                            <div style={{ color: "white", marginTop: "60px", fontSize: "15px", marginBottom: "20px" }}>
                                                <Typography style={{ fontSize: "15px" }}>
                                                    <b>Hermes Recruitment</b> nodigt u uit op de <b>jaarlijks Hermes Job & Stage Event</b>.
                                                    <br />
                                                    Deze zal fysiek plaatsvinden plaatsvinden in het ICC, wij zullen <b>100 bedrijven</b> hebben die voor u zullen klaarstaan.
                                                    <br />
                                                    <br /><br />
                                                    <b style={{ fontSize: "20px" }}>Welke studenten kunnen hieraan deelnemen?</b>
                                                    <ul>
                                                        <li>Studenten die op zoek zijn naar een bedrijf om hun <b>bachelor/master-stage</b> bij te doen</li>
                                                        <li>Studenten die op zoek zijn naar een <b>eerste werkplek</b></li>
                                                        <li>Studenten die nog niet afstuderen maar wel al eens een kijkje willen nemen naar de mogelijkheden binnen hun branche en hun <b>eerste contacten</b> wensen te leggen binnen de <b>bedrijfswereld</b>.</li>
                                                    </ul>
                                                    Wij richten ons voornamelijk op de studenten van de opleiding <b>Industrieel Ingenieur</b> aan de <b>Universiteit Gent</b> (afdeling Gent en Kortrijk) alsook de studenten van de <b>gerelateerde professionele bachelors</b> aan de <b>Hogeschool Gent</b>.
                                                    <br /><br />
                                                    <b style={{ fontSize: "20px" }}>Vragen of ondervindt u een probleem</b><br />
                                                    U kunt ons altijd contacteren op een van onderstaande manieren, wij proberen u zo snel mogelijk verder te helpen:<br /><br />
                                                    Mail ons : recruitment@hermesgent.be<br />
                                                    <a href="https://www.facebook.com/HermesRecruit">Stuur ons een bericht via Facebook (Hermes Recruitment)</a>
                                                </Typography>
                                            </div>

                                        </Grid>
                                        <Grid item xs={1}> <Divider orientation="vertical" style={{ background: "white", height: "80%", marginTop: "10%", marginLeft: "50%" }} /></Grid>
                                        <Grid item xs={4} >
                                            <div style={{ color: "white", marginTop: "60px", marginLeft: "80px" }}>
                                                <Typography style={{ fontSize: "15px" }}>
                                                    <b style={{ fontSize: "20px" }}>We bieden volgende richtingen aan:</b>
                                                    <br />
                                                    <ul>
                                                        <li><b>Ghent University</b>
                                                            <ul>
                                                                <li>Industrial Engineering:
                                                        <ul>
                                                                        <li>Electronics
                                                                    <ul>
                                                                                <li>Electronics</li>
                                                                                <li>ICT</li>
                                                                            </ul>
                                                                        </li>
                                                                        <li>Electromechanics
                                                                <ul>
                                                                                <li>Electromechanics</li>
                                                                                <li>Electrotechnics</li>
                                                                                <li>Automatisation</li>
                                                                            </ul>
                                                                        </li>
                                                                        <li>Computer Science</li>
                                                                        <li>Chemistry</li>

                                                                        <li>Structural engineering</li>
                                                                        <li>Land Surveyor</li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <b>University College Ghent</b>
                                                            <ul>
                                                                <li>Applied Information Technology</li>
                                                                <li>Chemistry</li>
                                                                <li>Electromechanics</li>
                                                                <li>Land Surveyor</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden xlUp>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 150px)", minWidth: "800px"}}>
                        <Grid item xs={12} style={{ marginTop: "" }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container justify="center" >
                                        <Grid item xs={10} style={{ marginTop: "50px", marginBottom: "50px" }}>
                                            <img alt="" src={Jobbeurs_image} style={{ width: "100%", height: "100%" }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <div style={{ backgroundColor: "#3D3D3D", width: "100%" }}>
                                    <Grid container justify="center">
                                    <Grid item xs={12} >
                                            <Typography style={{ textAlign: "center", color: "white", marginTop: "20px", fontSize: "30px" }}>
                                                <b>Annual Job & Internship Fair</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} >
                                            <div style={{ color: "white", marginTop: "60px", fontSize: "15px", marginBottom: "20px" }}>
                                                <Typography style={{ fontSize: "15px" }}>
                                                    <b>Hermes Recruitment</b> nodigt u uit op de <b>jaarlijks Hermes Job & Stage Event</b>.
                                                    <br />
                                                    Deze zal fysiek plaatsvinden plaatsvinden in het ICC, wij zullen <b>100 bedrijven</b> hebben die voor u zullen klaarstaan.
                                                    <br />
                                                    <br /><br />
                                                    <b style={{ fontSize: "20px" }}>Welke studenten kunnen hieraan deelnemen?</b>
                                                    <ul>
                                                        <li>Studenten die op zoek zijn naar een bedrijf om hun <b>bachelor/master-stage</b> bij te doen</li>
                                                        <li>Studenten die op zoek zijn naar een <b>eerste werkplek</b></li>
                                                        <li>Studenten die nog niet afstuderen maar wel al eens een kijkje willen nemen naar de mogelijkheden binnen hun branche en hun <b>eerste contacten</b> wensen te leggen binnen de <b>bedrijfswereld</b>.</li>
                                                    </ul>
                                                    Wij richten ons voornamelijk op de studenten van de opleiding <b>Industrieel Ingenieur</b> aan de <b>Universiteit Gent</b> (afdeling Gent en Kortrijk) alsook de studenten van de <b>gerelateerde professionele bachelors</b> aan de <b>Hogeschool Gent</b>.
                                                    <br /><br />
                                                    <b style={{ fontSize: "20px" }}>Vragen of ondervindt u een probleem</b><br />
                                                    U kunt ons altijd contacteren op een van onderstaande manieren, wij proberen u zo snel mogelijk verder te helpen:<br /><br />
                                                    Mail ons : recruitment@hermesgent.be<br />
                                                    <a href="https://www.facebook.com/HermesRecruit">Stuur ons een bericht via Facebook (Hermes Recruitment)</a>
                                                </Typography>
                                            </div>

                                        </Grid>
                                        <Grid item xs={1}> <Divider orientation="vertical" style={{ background: "white", height: "80%", marginTop: "10%", marginLeft: "50%" }} /></Grid>
                                        <Grid item xs={4} >
                                            <div style={{ color: "white", marginTop: "60px", marginLeft: "80px" }}>
                                                <Typography style={{ fontSize: "15px" }}>
                                                    <b style={{ fontSize: "20px" }}>We bieden volgende richtingen aan:</b>
                                                    <br />
                                                    <ul>
                                                        <li><b>Ghent University</b>
                                                            <ul>
                                                                <li>Industrial Engineering:
                                                        <ul>
                                                                        <li>Electronics
                                                                    <ul>
                                                                                <li>Electronics</li>
                                                                                <li>ICT</li>
                                                                            </ul>
                                                                        </li>
                                                                        <li>Electromechanics
                                                                <ul>
                                                                                <li>Electromechanics</li>
                                                                                <li>Electrotechnics</li>
                                                                                <li>Automatisation</li>
                                                                            </ul>
                                                                        </li>
                                                                        <li>Computer Science</li>
                                                                        <li>Chemistry</li>

                                                                        <li>Structural engineering</li>
                                                                        <li>Land Surveyor</li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <b>University College Ghent</b>
                                                            <ul>
                                                                <li>Applied Information Technology</li>
                                                                <li>Chemistry</li>
                                                                <li>Electromechanics</li>
                                                                <li>Land Surveyor</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
            </div >
        );
    }
}

export default Event;
