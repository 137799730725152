import React from "react";
import RecruitmentR from '../static/images/RecruitmentR_white.png'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl1: null,
            anchorEl2: null,
            loggedIn: localStorage.getItem("token") ? true : false,
            user: null
        }
        if (localStorage.getItem("user")) {
            this.setState({user: JSON.parse(localStorage.getItem("user"))});
        }
    }

    handleClose = () => {
        this.setState({ anchorEl1: null, anchorEl2: null });
    };

    render() {
        let button;
        if (this.state.loggedIn) {
            button = <Button color="inherit" href="/logout">Log out</Button>
        } else {
            button = <Button color="inherit" href="/login">Log in</Button>
        }
        return (
            <AppBar position="static" style={{ background: '#292929', height: '80px' }}>
                <Toolbar>
                    <Grid container spacing={12} >
                        <Grid item xs={2} style={{ textAlign: "right" }}>
                            <a href="/home">
                                <img
                                    src={RecruitmentR}
                                    width="70"
                                    height="50"
                                    alt="Hermes Recruitment logo"
                                    style={{ marginTop: '15px' }}
                                />
                            </a>
                        </Grid>
                        <Hidden mdDown>
                            <Grid item xs={8} />
                            <Grid item xs={1} style={{ marginTop: '25px', textAlign: "right", color: '#white' }}>
                                {button}
                            </Grid>
                        </Hidden>
                        <Hidden lgUp>
                            <Grid item xs={9} style={{ marginTop: '25px', textAlign: "right" }}>
                                {button}
                            </Grid>
                        </Hidden>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }
}

export default NavBar;
