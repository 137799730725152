import React from "react";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import MailIcon from '@material-ui/icons/Mail';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CopyrightIcon from '@material-ui/icons/Copyright';
import RecruitmentLogo_white from '../static/images/RecruitmentLogo_white.png'



class Footer extends React.Component {
    getYear = () => {
        return new Date().getFullYear();
    }

    render() {
        return (
            <div style={{ background: '#292929' }}>
                <Hidden mdDown>
                    <Grid container spacing={12} style={{ color: 'white', height: "150px" }}>
                        <Grid item xs={4} style={{ marginTop: '50px', textAlign: "center" }}>
                            <a href="mailto:recruitment@hermesgent.be" style={{ color: 'white' }}>
                                <MailIcon style={{ color: 'white' }} /> recruitment@hermesgent.be
                            </a> <br />
                            <LocationOnIcon /> Valentin Vaerwyckweg 1, 9000 Gent
                            <br />
                            Lokaal GSCHC.0.170
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "center", marginTop: '20px' }}>
                            <img src={RecruitmentLogo_white} width="250"
                                height="90"
                                alt="Hermes Recruitment logo"
                                style={{ marginLeft: "auto", marginRight: "auto", display: "block" }} />
                            <CopyrightIcon fontSize='small' /> {this.getYear()} Hermes Gent VZW
                        </Grid>
                        <Grid item xs={4} style={{ marginTop: '35px', textAlign: "center" }}>
                            <a href="https://www.linkedin.com/company/hermesgentvzw" style={{ color: 'white' }}>
                                <LinkedInIcon style={{ width: "40px", height: "40px" }} />
                            </a>
                            <a href="https://www.facebook.com/HermesRecruit" style={{ color: 'white' }}>
                                <FacebookIcon style={{ width: "40px", height: "40px" }} />
                            </a>
                            <a href="https://www.instagram.com/hermesgent/" style={{ color: 'white' }}>
                                <InstagramIcon style={{ width: "40px", height: "40px" }} />
                            </a>
                            <br />
                            <AccountBalanceIcon fontSize='small' /> Hermes Recruitment: <br />
                            <b>BE49 7370 2547 5071</b>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden lgUp>
                    <Grid container spacing={12} style={{ color: 'white', height: "260px", textAlign: "center" }}>
                        <Grid item xs={12} style={{ marginTop: "25px" }}>
                            <a href="mailto:recruitment@hermesgent.be" style={{ color: 'white' }}>
                                <MailIcon style={{ color: 'white' }} /> recruitment@hermesgent.be
                            </a> <br />
                            <LocationOnIcon /> Valentin Vaerwyckweg 1, 9000 Gent
                            <br />
                            Lokaal GSCHC.0.170
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            <a href="https://www.linkedin.com/company/hermesgentvzw" style={{ color: 'white' }}>
                                <LinkedInIcon style={{ width: "40px", height: "40px" }} />
                            </a>
                            <a href="https://www.facebook.com/HermesRecruit" style={{ color: 'white' }}>
                                <FacebookIcon style={{ width: "40px", height: "40px" }} />
                            </a>
                            <a href="https://www.instagram.com/hermesgent/" style={{ color: 'white' }}>
                                <InstagramIcon style={{ width: "40px", height: "40px" }} />
                            </a>
                            <br />
                            <AccountBalanceIcon fontSize='small' /> Hermes Recruitment: <br />
                            <b>BE49 7370 2547 5071</b>
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                            <CopyrightIcon fontSize='small' /> {this.getYear()} Hermes Gent VZW
                        </Grid>
                    </Grid>
                </Hidden>
            </div>
        );
    }
}

export default Footer;
