import React from 'react';
import Jobbeurs_image_mobile from '../static/images/jobbeurs_home_mobile.jpg'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'


function App() {

    

    function changeOpacity(e) {
        console.log(e.target)
        if (e.target.className !== "MuiButton-label") {
            e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        }
    }

    function fixOpacity(e) {
        if (e.target.className !== "MuiButton-label") {
            e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.2)';
        }
    }

    return (
        <div>
            <div style={{ position: 'relative' }}>
                <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 150px)", backgroundImage: `url(${Jobbeurs_image_mobile})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
                    <Grid item xs={6}>
                        <Button color="contained" style={{ borderColor: 'white', color: 'white', height: "100%", width: "100%", backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
                            onMouseOver={changeOpacity}
                            onMouseLeave={fixOpacity}
                            href="/student">
                            Student
                            </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" style={{ borderColor: 'white', color: 'white', height: "100%", width: "100%", backgroundColor: 'rgba(0, 0, 0, .2)' }}
                            onMouseOver={changeOpacity}
                            onMouseLeave={fixOpacity}
                            href="/company">
                            Company
                            </Button>
                    </Grid>
                </Grid>
            </div>
        </div >
    );
}

export default App;
