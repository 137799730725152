import React from "react";
import VacatureList from "../shared/Vacatures";

class Internships extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
        }
    }

    render() {
        return (
            <div>
                <VacatureList url={"jobbeurs/vacatures/"} authenticate={false} soort={"internship"}/>
            </div>
        );
    }
}

export default Internships;
