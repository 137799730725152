import React from "react";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import VacatureList from "./CompanyVacancies"
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

class MyVacancies extends React.Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
            pk: props.par.pk,
        }
    }

    render() {
        return (
            <div>
                <Grid container style={{ marginTop: "20px" }}>
                    <Grid item xs={2} />
                    <Grid item xs={10}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href={"/company/companies/" + this.state.pk + "/manage/"}>
                                My company
                            </Link>
                            <Typography color="textPrimary">Manage my vacancies</Typography>
                        </Breadcrumbs>
                    </Grid>                    
                </Grid>
                <VacatureList url={"jobbeurs/companies/" + this.state.pk + "/vacatures/all/"} authenticate={true} soort={"both"} pk={this.state.pk} />
            </div>
        );
    }
}

export default MyVacancies;
