import React from "react";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Lost from "../shared/NoMatch"
import { get, refresh, getUrl } from "../shared/AuthLogic";

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshed: false,
            mail: "",
            mail2: "",
            invalid: false,
            error: "",
            made: false,
            pk: props.par.pk,
            disabled: false,
            exists: true,
        };
        get("jobbeurs/companies/" + this.state.pk)
            .then(json => {
                if (json !== false) {
                    this.setState({
                        account: json.account,
                        exists: true,
                    });
                } else {
                    this.setState({exists: false});
                }
            });
    }

    create = () => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.mail) && this.state.mail.toLowerCase() === this.state.mail2.toLowerCase()) {
            this.setState({ invalid: false, error: "", disabled: true });
            fetch(getUrl() + 'jobbeurs/company/register/no_account/add/' + this.state.pk + "/", {
                method: "Post",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "account": { "email": this.state.mail.toLowerCase() } })
            })
                .then(res => {
                    if (res.status === 201) {
                        this.setState({ made: true });
                        document.location.href = "/company/companies/" + this.state.pk;
                    } else if (res.status === 400) {
                        this.setState({ invalid: true, error: "A user with this email already exists already exists", disabled: false });
                    } else if (res.status === 401) {
                        console.log("Updating token");
                        if (!this.state.refreshed) {
                            this.setState({ refreshed: true }).then(() => {
                                refresh().then(() => this.create());
                            },
                                reason => {
                                    console.log(reason);
                                });
                        } else {
                            alert("We cannot refresh your token, please log out and log back in to manually refresh it")
                        }
                    } else {
                        console.log(res);
                        alert("Something went wrong, " + res.status + ", if this keeps occurring please contact ict@hermesgent.be");
                    }
                }, reason => {
                    this.setState({ invalid: true, error: "Something went wrong, " + JSON.stringify(reason) + ", if this keeps occurring please contact ict@hermesgent.be", disabled: false });
                })
        } else if (this.state.mail.toLowerCase() !== this.state.mail2.toLowerCase()) {
            this.setState({ invalid: true, error: "Both email addresses do no match!", disabled: false });
        } else {
            this.setState({ invalid: true, error: "You have entered an invalid email!", disabled: false });
        }
    }

    render() {
        let errorMessage;
        if (this.state.invalid) {
            errorMessage = <Typography style={{ color: "red" }}>{this.state.error}</Typography>;
        } else {
            errorMessage = <br />;
        }
        let page;
        page = <div>
            <Hidden mdDown>
                <div style={{ minHeight: "calc(100vh - 80px - 150px)" }}>
                    <Grid container spacing={12} justify="center">
                        <Grid item xs={10} style={{ marginTop: "50px" }}>
                            <Typography variant="h6" component="h2" style={{ textAlign: "center" }}>
                                Add account to this company
                                </Typography>
                            <br />
                            <Card variant="outlined" style={{ maxWidth: "400px", marginLeft: "auto", marginRight: "auto", display: "block" }}>
                                <CardContent >
                                    <Typography gutterBottom>
                                        Email address
                                        </Typography>
                                    <TextField error={this.state.invalid} autoFocus
                                        fullWidth label="Email" name="email" size="small" variant="outlined"
                                        onChange={e => this.setState({ mail: e.target.value })}
                                        value={this.state.mail}
                                        style={{ maxWidth: "400px" }}
                                        disabled={this.state.disabled}
                                    />
                                    <br />
                                    <Typography gutterBottom>
                                        Confirm email address
                                        </Typography>
                                    <TextField
                                        error={this.state.invalid}
                                        fullWidth
                                        label="Confirm email"
                                        name="mail2"
                                        size="small"
                                        variant="outlined"
                                        onChange={e => this.setState({ mail2: e.target.value })}
                                        value={this.state.mail2}
                                        style={{ maxWidth: "400px" }}
                                        disabled={this.state.disabled}
                                    />
                                    {errorMessage}
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={12} justify="center">
                                        <Grid item xs={6}>
                                            <Button disabled={this.state.disabled} size="small" variant="contained" style={{ backgroundColor: "green", color: "white" }} onClick={this.create}>Add account</Button>
                                        </Grid>
                                    </Grid>

                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </Hidden>
            <Hidden lgUp>
                <div style={{ minHeight: "calc(100vh - 80px - 260px)" }}>
                    <Grid container spacing={12} justify="center">
                        <Grid item xs={10} style={{ marginTop: "50px" }}>
                            <Typography variant="h6" component="h2" style={{ textAlign: "center" }}>
                                Add account to this company
                                </Typography>
                            <br />
                            <Card variant="outlined" style={{ maxWidth: "400px", marginLeft: "auto", marginRight: "auto", display: "block" }}>
                                <CardContent >
                                    <Typography gutterBottom>
                                        Email address
                                        </Typography>
                                    <TextField error={this.state.invalid} autoFocus
                                        fullWidth label="Email" name="email" size="small" variant="outlined"
                                        onChange={e => this.setState({ mail: e.target.value })}
                                        value={this.state.mail}
                                        style={{ maxWidth: "400px" }}
                                        disabled={this.state.disabled}
                                    />
                                    <br />
                                    <Typography gutterBottom>
                                        Confirm email address
                                        </Typography>
                                    <TextField
                                        error={this.state.invalid}
                                        fullWidth
                                        label="Confirm email"
                                        name="mail2"
                                        size="small"
                                        variant="outlined"
                                        onChange={e => this.setState({ mail2: e.target.value })}
                                        value={this.state.mail2}
                                        style={{ maxWidth: "400px" }}
                                        disabled={this.state.disabled}
                                    />
                                    {errorMessage}
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={12} justify="center">
                                        <Grid item xs={6}>
                                            <Button disabled={this.state.disabled} size="small" variant="contained" style={{ backgroundColor: "green", color: "white" }} onClick={this.create}>Add account</Button>
                                        </Grid>
                                    </Grid>

                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </Hidden>
        </div >;
        if (this.state.account !== null || !this.state.exists) {
            page = <Lost />
        }
        return (
            page
        )
    }
}

export default Admin;
