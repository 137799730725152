import React from "react";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Hidden from '@material-ui/core/Hidden';
import RecruitmentR from '../static/images/RecruitmentR.png'
import {
    Link
} from "react-router-dom";
import { getUrl, getClientData, get, put, post } from "./AuthLogic";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mail: null,
            password: "",
            invalid: false,
            error: "",
            disabled: false,
        };
    }

    login = (e) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.mail) && this.state.password !== "") {
            this.setState({ invalid: false, error: "", disabled: true });
            let clientdata = getClientData();
            fetch(getUrl() + 'auth/o/token/', {
                method: "Post",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: "grant_type=password&client_secret=" + clientdata.clientSecret + "&client_id=" + clientdata.clientId + "&username=" + this.state.mail + "&password=" + this.state.password
            })
                .then(res => {
		    console.log(res);
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 400) {
                        this.setState({ invalid: true, error: "Invalid credentials", disabled: false });
                        return null;
                    }

                }, reason => {
		    console.log(reason);
                    this.setState({ invalid: true, error: "Something went wrong, " + JSON.stringify(reason) + ", if this keeps occurring please contact ict@hermesgent.be", disabled: false});
                })
                .then(json => {
                    if (json !== null && "access_token" in json) {
                        localStorage.setItem('token', json.access_token);
                        localStorage.setItem('refreshToken', json.refresh_token);
                    }
                })
                .then(() => {
                    if (localStorage.getItem("token")) {
                        get("jobbeurs/users/me/", true)
                            .then(json => {
                                if (json !== false) {
                                    localStorage.setItem('user', JSON.stringify(json));
                                    if (this.state.mail.toLowerCase() === "julien.fouquet@hermesgent.be") {
                                        localStorage.setItem('Julien', true);
                                    }
                                    if (json.isStudent || json.isRecruitment) {
                                        document.location.href = "/student";
                                    } else {
                                        if (json.first_time) {
                                            let body = {
                                                email: this.state.mail
                                            };
                                            post('auth/reset/password-reset/reset_password/', body)
                                                .then(json => {
                                                    if (json !== false) {
                                                        put("auth/user/me/", {}, true)
                                                            .then(json => {
                                                                if (json !== false) {
                                                                    document.location.href = "/company/welcome";
                                                                } else {
                                                                    alert("Something went wrong, if this keeps occurring please contact ict@hermesgent.be.");
                                                                }
                                                            });

                                                    } else {
                                                        alert("Something went wrong, if this keeps occurring please contact ict@hermesgent.be.");
                                                    }
                                                });
                                        } else {
                                            document.location.href = "/company";
                                        }
                                    }
                                } else {
                                    alert("Something went wrong, if this keeps occurring please contact ict@hermesgent.be.");
                                }
                            });
                    }
                })
        } else if (this.state.password !== "") {
            this.setState({ invalid: true, error: "You have entered an invalid email!", disabled: false });
        } else {
            this.setState({ invalid: true, error: "You have entered an invalid email and no password!", disabled: false });
        }
    }

    render() {
        let errorMessage;
        if (this.state.invalid) {
            errorMessage = <Typography style={{ color: "red" }}>{this.state.error}</Typography>;
        } else {
            errorMessage = <br />;
        }
        let page;
        if (!localStorage.getItem('token')) {
            page = <div>
                <Hidden lgDown>
                    <Grid container spacing={12} style={{ height: "calc(100vh - 80px - 120px)" }} justify="center">
                        <Grid item xs={2} style={{ marginTop: "50px" }}>
                            <img
                                src={RecruitmentR}
                                width="80"
                                height="60"
                                alt="Hermes Recruitment logo"
                                style={{ marginTop: '15px', marginLeft: "auto", marginRight: "auto", display: "block" }}
                            />
                            <Typography variant="h6" component="h2" style={{ textAlign: "center" }}>
                                Sign in
                            </Typography>
                            <br />
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom>
                                        Email address
                                    </Typography>
                                    <TextField error={this.state.invalid} autoFocus
                                        fullWidth label="Email" name="email" size="small" variant="outlined"
                                        onChange={e => this.setState({ mail: e.target.value })}
                                        value={this.state.mail}
                                        disabled={this.state.disabled}
                                        onKeyPress={e => {
                                            if (e.key === "Enter") {
                                                this.login(e);
                                            }
                                        }}
                                    />
                                    <br />
                                    <Typography gutterBottom>
                                        Password
                                    </Typography>
                                    <TextField
                                        error={this.state.invalid}
                                        fullWidth
                                        label="Password"
                                        name="password"
                                        size="small"
                                        type="password"
                                        variant="outlined"
                                        onChange={e => this.setState({ password: e.target.value })}
                                        value={this.state.password}
                                        disabled={this.state.disabled}
                                        onKeyPress={e => {
                                            if (e.key === "Enter") {
                                                this.login(e);
                                            }
                                        }}
                                    />
                                    {errorMessage}
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={12} >
                                        <Grid item xs={5}>
                                            <Button disabled={this.state.disabled} size="small" variant="contained" style={{ backgroundColor: "green", color: "white" }} onClick={this.login}>Login</Button>
                                        </Grid>
                                        <Grid item xs={2} />
                                        <Grid item xs={4}>
                                            <Button component={Link} to="/reset" style={{ color: "blue", fontSize: 11 }}>
                                                Forgot password?
                                        </Button>
                                        </Grid>
                                    </Grid>

                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden xlUp>
                    <Grid container spacing={12} style={{ height: "calc(100vh - 80px - 150px)", minHeight: "450px", marginTop: "50px" }} justify="center">
                        <Grid item xs={8}>
                            <img
                                src={RecruitmentR}
                                width="80"
                                height="60"
                                alt="Hermes Recruitment logo"
                                style={{ marginTop: '15px', marginLeft: "auto", marginRight: "auto", display: "block" }}
                            />
                            <br />
                            <Typography variant="h6" component="h2" style={{ textAlign: "center" }}>
                                Sign in
                            </Typography>
                            <br />
                            <Card variant="outlined" style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "300px" }}>
                                <CardContent>
                                    <Typography gutterBottom>
                                        Email address
                                    </Typography>
                                    <TextField disabled={this.state.disabled} onKeyPress={e => {
                                            if (e.key === "Enter") {
                                                this.login(e);
                                            }
                                        }}
                                        autoFocus error={this.state.invalid} fullWidth label="Email" name="email" size="small" variant="outlined" onChange={e => this.setState({ mail: e.target.value })} value={this.state.mail} />
                                    <br />
                                    <Typography gutterBottom>
                                        Password
                                    </Typography>
                                    <TextField
                                        error={this.state.invalid}
                                        fullWidth
                                        label="Password"
                                        name="password"
                                        size="small"
                                        type="password"
                                        variant="outlined"
                                        onChange={e => this.setState({ password: e.target.value })}
                                        value={this.state.password}
                                        disabled={this.state.disabled}
                                        onKeyPress={e => {
                                            if (e.key === "Enter") {
                                                this.login(e);
                                            }
                                        }}
                                    />
                                    {errorMessage}
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={12} >
                                        <Grid item xs={5}>
                                            <Button disabled={this.state.disabled} size="small" variant="contained" onClick={this.login} style={{ backgroundColor: "green", color: "white" }}>Login</Button>
                                        </Grid>
                                        <Grid item xs={2} />
                                        <Grid item xs={4}>
                                            <Button component={Link} to="/reset" style={{ color: "blue", fontSize: 11 }}>
                                                Forgot password?
                                        </Button>
                                        </Grid>
                                    </Grid>

                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>
            </div >;
        } else {
            page = <div>
                <Hidden lgDown>
                    <Grid container spacing={12} style={{ height: "calc(100vh - 80px - 120px)" }} justify="center">
                        <Grid item xs={2} style={{ textAlign: "center", marginTop: "100px" }}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom>
                                        You are already logged in
                                </Typography>
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={12} justify="center" >
                                        <Grid item xs={4}>
                                            <Button component={Link} to="/home" style={{ color: "blue", fontSize: 11 }}>
                                                Take me back home
                                        </Button>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden xlUp>
                    <Grid container spacing={12} style={{ height: "calc(100vh - 80px - 150px)", minHeight: "400px", marginTop: "50px" }} justify="center">
                        <Grid item xs={8} style={{ textAlign: "center" }}>
                            <Card variant="outlined" style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "300px", marginTop: "100px" }}>
                                <CardContent>
                                    <Typography gutterBottom>
                                        You are already logged in
                                </Typography>
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={12} justify="center">
                                        <Grid item xs={4}>
                                            <Button component={Link} to="/home" style={{ color: "blue", fontSize: 11 }}>
                                                Take me back home
                                        </Button>
                                        </Grid>
                                    </Grid>

                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>
            </div >;
        }
        return (
            page
        )
    }
}

export default LoginPage;
