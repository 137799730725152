import React from "react";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/Typography";
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CardActions from '@material-ui/core/CardActions';
import RecruitmentR from '../static/images/RecruitmentR.png'
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import { get } from "./AuthLogic";

class CompanyList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            admin: props.admin,
            isStudent: props.isStudent,
            companies: [],
            next: null,
            previous: null,
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
            baseUrl: props.url,
            authenticate: props.authenticate,
            count: 0,
            page: 1,
            search: "",
            international: false,
            master: false,
            elektromech: false,
            infor: false,
            chem: false,
            bouw: false,
            auto: false,
            land: false,
            eict: false,
            etech: false,
        }
        this.getItems();
    }

    recalcUrl = () => {
        let url = this.state.baseUrl.concat("?");
        if (this.state.international) {
            url = url.concat("international=True");
        }
        if (this.state.master) {
            url = url.concat("&masterproeven=True");
        }
        if (this.state.search !== "") {
            url = url.concat("&name=".concat(this.state.search));
        }
        if (this.state.elektromech) {
            url = url.concat("&interests=elektromech");
        }
        if (this.state.infor) {
            url = url.concat("&interests=infor");
        }
        if (this.state.chem) {
            url = url.concat("&interests=chem");
        }
        if (this.state.bouw) {
            url = url.concat("&interests=bouw");
        }
        if (this.state.auto) {
            url = url.concat("&interests=auto");
        }
        if (this.state.land) {
            url = url.concat("&interests=land");
        }
        if (this.state.eict) {
            url = url.concat("&interests=eict");
        }
        if (this.state.etech) {
            url = url.concat("&interests=etech");
        }
        return url.concat("&page=" + this.state.page);
    }

    getItems = () => {
        get(this.recalcUrl(), this.state.authenticate).then(json => {
            if (json !== false) {
                this.setState({
                    page: 1,
                    companies: json.results.sort(() => 0.5 - Math.random()),
                    next: json.next,
                    previous: json.previous,
                    count: json.count,
                });
            }
        });
    }

    pagination = async (event, value) => {
        await this.setState({ page: value });
        this.getItems();
    }

    render() {
        return (
            <div>
                <Hidden lgDown>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 110px)", marginTop: "50px" }} justify="center">
                        <Grid item xs={2} style={{ marginTop: "-20px", marginRight: "5%" }}>
                            <Card>
                                <CardContent>
                                    <Typography>
                                        <b>Filter/Search</b>
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        label="Search"
                                        name="Search"
                                        size="small"
                                        variant="outlined"
                                        onChange={e => this.setState({ search: e.target.value })}
                                        value={this.state.search}
                                        onKeyPress={e => {
                                            if (e.key === "Enter") {
                                                this.getItems();
                                            }
                                        }}
                                    />
                                    <Divider style={{ marginTop: "20px" }} />
                                    <Typography>
                                        <b>Field</b>
                                    </Typography>
                                    <Tooltip title="Students who study computer science">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.infor}
                                                    onChange={e => this.setState({ infor: !this.state.infor })}
                                                    name="Computer science"
                                                    color="primary"
                                                />
                                            } label="Computer science"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study electromechanics">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.elektromech}
                                                    onChange={e => this.setState({ elektromech: !this.state.elektromech })}
                                                    name="Electromechanics"
                                                    color="primary"
                                                />
                                            } label="Electromechanics"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study chemistry">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.chem}
                                                    onChange={e => this.setState({ chem: !this.state.chem })}
                                                    name="Chemistry"
                                                    color="primary"
                                                />
                                            } label="Chemistry"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study structural engineering">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.bouw}
                                                    onChange={e => this.setState({ bouw: !this.state.bouw })}
                                                    name="Structural engineering"
                                                    color="primary"
                                                />
                                            } label="Structural engineering"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study automation">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.auto}
                                                    onChange={e => this.setState({ auto: !this.state.auto })}
                                                    name="Automation"
                                                    color="primary"
                                                />
                                            } label="Automation"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study Land surveyor">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.land}
                                                    onChange={e => this.setState({ land: !this.state.land })}
                                                    name="Land surveyor"
                                                    color="primary"
                                                />
                                            } label="Land surveyor"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study electronical-ICT">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.eict}
                                                    onChange={e => this.setState({ eict: !this.state.eict })}
                                                    name="Electronical-ICT"
                                                    color="primary"
                                                />
                                            } label="Electronical-ICT"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study electronical-ICT">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.etech}
                                                    onChange={e => this.setState({ etech: !this.state.etech })}
                                                    name="Electrotechnics"
                                                    color="primary"
                                                />
                                            } label="Electrotechnics"
                                        />
                                    </Tooltip>
                                    <Divider style={{ marginTop: "20px" }} />
                                    <Tooltip title="Is this company active internationally">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.international}
                                                    onChange={e => this.setState({ international: !this.state.international })}
                                                    name="International"
                                                    color="primary"
                                                />
                                            } label="International"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students can contact this company for their master's thesis">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.master}
                                                    onChange={e => this.setState({ master: !this.state.master })}
                                                    name="Masters"
                                                    color="primary"
                                                />
                                            } label="Master's thesis"
                                        />
                                    </Tooltip>
                                    <Divider style={{ marginTop: "20px" }} />
                                </CardContent>
                                <CardActions>
                                    <Grid container justify="center">
                                        <Button variant="contained" onClick={this.getItems} disabled={false} style={{ marginBottom: "10px" }}>
                                            Search
                                        </Button>
                                    </Grid>

                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={7} style={{}}>
                            <Typography variant="h3" style={{ marginBottom: "20px" }}>
                                Companies
                            </Typography>
                            {this.state.companies.length === 0 ? <Typography>No results</Typography> : <div />}
                            {this.state.companies.map((company, index) => {
                                return <a href={this.state.isStudent ? "/student/companies/" + company.pk : "/company/companies/" + company.pk}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid container>
                                                <Grid item xs={2}>
                                                    <div style={{ minHeight: "50px", minWidth: "50px", maxWidth: "100px", maxHeight: "100px", marginTop: '5px', marginBottom: '5px' }}>
                                                        <img
                                                            src={company.logo === null ? RecruitmentR : company.logo}
                                                            alt=""
                                                            style={{ marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", display: "block", maxWidth: "100%", maxHeight: "100%" }}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography>
                                                                <b>{company.name}</b>
                                                            </Typography>
                                                        </Grid>
                                                        {this.state.admin && company.account === null ?
                                                            <Grid item xs={12}>
                                                                <Chip label="NO ACCOUNT" color="secondary" />
                                                            </Grid> : <div />}
                                                        <Grid item xs={12}>
                                                            <Typography style={{}}>
                                                                {company.desc_long.length > 400 ? company.desc_long.substring(0, 400) + "..." : company.desc_long}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </a>
                            })}
                        </Grid>
                        <Grid item xs={12} >
                            <Pagination style={{ justifyContent: "center", display: 'flex', alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}
                                count={Math.ceil(this.state.count / 10)} shape="rounded" color="primary"
                                onChange={this.pagination} />
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden xlUp>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 110px)", marginTop: "50px" }} justify="center">
                        <Grid item xs={4} style={{ marginTop: "-20px", marginRight: "5%" }}>
                            <Card>
                                <CardContent>
                                    <Typography>
                                        <b>Filter/Search</b>
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        label="Search"
                                        name="Search"
                                        size="small"
                                        variant="outlined"
                                        onChange={e => this.setState({ search: e.target.value })}
                                        value={this.state.search}
                                        onKeyPress={e => {
                                            if (e.key === "Enter") {
                                                this.getItems();
                                            }
                                        }}
                                    />
                                    <Divider style={{ marginTop: "20px" }} />
                                    <Typography>
                                        <b>Field</b>
                                    </Typography>
                                    <Tooltip title="Students who study computer science">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.infor}
                                                    onChange={e => this.setState({ infor: !this.state.infor })}
                                                    name="Computer science"
                                                    color="primary"
                                                />
                                            } label="Computer science"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study electromechanics">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.elektromech}
                                                    onChange={e => this.setState({ elektromech: !this.state.elektromech })}
                                                    name="Electromechanics"
                                                    color="primary"
                                                />
                                            } label="Electromechanics"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study chemistry">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.chem}
                                                    onChange={e => this.setState({ chem: !this.state.chem })}
                                                    name="Chemistry"
                                                    color="primary"
                                                />
                                            } label="Chemistry"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study structural engineering">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.bouw}
                                                    onChange={e => this.setState({ bouw: !this.state.bouw })}
                                                    name="Structural engineering"
                                                    color="primary"
                                                />
                                            } label="Structural engineering"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study automation">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.auto}
                                                    onChange={e => this.setState({ auto: !this.state.auto })}
                                                    name="Automation"
                                                    color="primary"
                                                />
                                            } label="Automation"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study Land surveyor">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.land}
                                                    onChange={e => this.setState({ land: !this.state.land })}
                                                    name="Land surveyor"
                                                    color="primary"
                                                />
                                            } label="Land surveyor"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study electronical-ICT">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.eict}
                                                    onChange={e => this.setState({ eict: !this.state.eict })}
                                                    name="Electronical-ICT"
                                                    color="primary"
                                                />
                                            } label="Electronical-ICT"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students who study electronical-ICT">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.etech}
                                                    onChange={e => this.setState({ etech: !this.state.etech })}
                                                    name="Electrotechnics"
                                                    color="primary"
                                                />
                                            } label="Electrotechnics"
                                        />
                                    </Tooltip>
                                    <Divider style={{ marginTop: "20px" }} />
                                    <Tooltip title="Is this company active internationally">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.international}
                                                    onChange={e => this.setState({ international: !this.state.international })}
                                                    name="International"
                                                    color="primary"
                                                />
                                            } label="International"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Students can contact this company for their master's thesis">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.master}
                                                    onChange={e => this.setState({ master: !this.state.master })}
                                                    name="Masters"
                                                    color="primary"
                                                />
                                            } label="Master's thesis"
                                        />
                                    </Tooltip>
                                    <Divider style={{ marginTop: "20px" }} />
                                </CardContent>
                                <CardActions>
                                    <Grid container justify="center">
                                        <Button variant="contained" onClick={this.getItems} disabled={false} style={{ marginBottom: "10px" }}>
                                            Search
                                        </Button>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={7} style={{}}>
                            <Typography variant="h3" style={{ marginBottom: "20px" }}>
                                Companies
                            </Typography>
                            {this.state.companies.length === 0 ? <Typography>No results</Typography> : <div />}
                            {this.state.companies.map((company, index) => {
                                return <a href={this.state.isStudent ? "/student/companies/" + company.pk : "/company/companies/" + company.pk}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid container>
                                                <Grid item xs={2}>
                                                    <div style={{ minHeight: "50px", minWidth: "50px", maxWidth: "100px", maxHeight: "100px", marginTop: '5px', marginBottom: '5px' }}>
                                                        <img
                                                            src={company.logo === null ? RecruitmentR : company.logo}
                                                            alt=""
                                                            style={{ marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", display: "block", maxWidth: "100%", maxHeight: "100%" }}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Grid container style={{ marginLeft: "20px" }}>
                                                        <Grid item xs={12}>
                                                            <Typography>
                                                                <b>{company.name}</b>
                                                            </Typography>
                                                        </Grid>
                                                        {this.state.admin && company.account === null ?
                                                            <Grid item xs={12}>
                                                                <Chip label="NO ACCOUNT" color="secondary" />
                                                            </Grid> : <div />}
                                                        <Grid item xs={12}>
                                                            <Typography style={{}}>
                                                                {company.desc_long.length > 50 ? company.desc_long.substring(0, 50) + "..." : company.desc_long}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </a>
                            })}
                        </Grid>
                        <Grid item xs={12} >
                            <Pagination style={{ justifyContent: "center", display: 'flex', alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}
                                count={Math.ceil(this.state.count / 10)} shape="rounded" color="primary"
                                onChange={this.pagination} />
                        </Grid>
                    </Grid>
                </Hidden>
            </div>
        )
    }
}

export default CompanyList;