const clientId = "MTjxWX3LoMe8HW1BQaOPO3X9VCbrgC3rl1R6X2Rw";
const clientSecret = "KMWZATw9rt6A0XfhNCd0gbCtmPZF2ICzVcEsEGa83b8fCRkjZ836Xtp4lLXIEkdDqUaSz2Xpssg7N9l5fSrWndIDOHrhxDaeS2UvbpxSFKEMPpWOU8QMr6iMsoIhY1mB";
const url = "https://backend.hermesgent.be/";

function getUrl() {
    return url;
}

function getClientData() {
    return { clientId: clientId, clientSecret: clientSecret };
}

function logout() {
    try {
        if (!localStorage.getItem('token')) throw new Error("tokenError");
        if (!localStorage.getItem('refreshToken')) throw new Error("refreshTokenError");
        if (!localStorage.getItem('user')) throw new Error("userError");
        let token = localStorage.getItem('token');
        return fetch(url + "auth/o/revoke_token/", {
            method: "Post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: "client_secret=" + clientSecret + "&client_id=" + clientId + "&token=" + token
        })
            .then(res => {
                if (res.status === 200) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("user");
                    if (localStorage.getItem("Julien")) {
                        localStorage.removeItem("Julien");
                    }
                    return true;
                } else {
                    return false;
                }
            });
    }
    catch (err) {
        console.log(err);
        if (localStorage.getItem("token")) {
            localStorage.removeItem("token");
        }
        if (localStorage.getItem("refreshToken")) {
            localStorage.removeItem("refreshToken");
        }
        if (localStorage.getItem("user")) {
            localStorage.removeItem("user");
        }
        return false;
    }
}

function put(link, body = null, authenticate = false, refreshed = false) {
    let token;
    if (authenticate) {
        try {
            if (!localStorage.getItem('token')) throw new Error("tokenError");
            token = localStorage.getItem('token');
        }
        catch (err) {
            if (localStorage.getItem("token")) {
                localStorage.removeItem("token");
            }
            if (localStorage.getItem("refreshToken")) {
                localStorage.removeItem("refreshToken");
            }
            return false;
        }
    }
    let headers = {};
    if (authenticate) {
        headers.Authorization = "Bearer " + token;
    }
    if (body !== null) {
        headers["Content-Type"] = 'application/json';
    }
    return fetch(url + link, {
        method: "put",
        headers: headers,
        body: JSON.stringify(body)
    })
    .then(res => {
        if (res.status === 200) {
            return true;
        } else if (res.status === 401 && authenticate && !refreshed) {
            console.log("refreshing");
            return refresh().then( success => {
                if (success) {
                    return put(link, body, authenticate, refreshed = true);
                } else {
                    return false;
                }
            })
        }
        return false;
    })
}

function post(link, body = null, authenticate = false, refreshed = false) {
    let token;
    if (authenticate) {
        try {
            if (!localStorage.getItem('token')) throw new Error("tokenError");
            token = localStorage.getItem('token');
        }
        catch (err) {
            if (localStorage.getItem("token")) {
                localStorage.removeItem("token");
            }
            if (localStorage.getItem("refreshToken")) {
                localStorage.removeItem("refreshToken");
            }
            return false;
        }
    }
    let headers = {};
    if (authenticate) {
        headers.Authorization = "Bearer " + token;
    }
    if (body !== null) {
        headers["Content-Type"] = 'application/json';
    }
    return fetch(url + link, {
        method: "post",
        headers: headers,
        body: JSON.stringify(body)
    })
    .then(res => {
        if (res.status === 201 || res.status === 200) {
            return true;
        } else if (res.status === 401 && authenticate && !refreshed) {
            console.log("refreshing");
            return refresh().then( success => {
                if (success) {
                    return post(link, body, authenticate, true);
                } else {
                    return false;
                }
            })
        }
        return false;
    })
}

function get(link, authenticate = false, refreshed = false) {
    let token;
    if (authenticate) {
        try {
            if (!localStorage.getItem('token')) throw new Error("tokenError");
            token = localStorage.getItem('token');
        }
        catch (err) {
            if (localStorage.getItem("token")) {
                localStorage.removeItem("token");
            }
            if (localStorage.getItem("refreshToken")) {
                localStorage.removeItem("refreshToken");
            }
            if (localStorage.getItem("user")) {
                localStorage.removeItem("user");
            }
            console.log("errRunning");
            return false;
        }
    }
    return fetch(url + link, {
        method: "Get",
        headers: authenticate ? {
            "Authorization": "Bearer " + token
        } : {}
    })
        .then(res => {
            if (res.status === 200) {
                return res.json();
            } else if (authenticate && !refreshed && res.status === 401) {
                return refresh().then( success => {
                    if (success) {
                        console.log("Refreshing");
                        return get(link, authenticate, true);
                    } else {
                        return false;
                    }
                });
            } else if (res.status === 404) {
                return false;
            }
            return false;
        });
}

function destroy(link, authenticate = false, refreshed = false) {
    let token;
    if (authenticate) {
        try {
            if (!localStorage.getItem('token')) throw new Error("tokenError");
            token = localStorage.getItem('token');
        }
        catch (err) {
            if (localStorage.getItem("token")) {
                localStorage.removeItem("token");
            }
            if (localStorage.getItem("refreshToken")) {
                localStorage.removeItem("refreshToken");
            }
            if (localStorage.getItem("user")) {
                localStorage.removeItem("user");
            }
            return false;
        }
    }
    return fetch(url + link, {
        method: "Delete",
        headers: authenticate ? {
            "Authorization": "Bearer " + token
        } : {}
    })
        .then(res => {
            if (res.status === 200 || res.status === 202) {
                return true;
            } else if (authenticate && !refreshed && res.status === 401) {
                return refresh().then( success => {
                    if (success) {
                        return destroy(link, authenticate, true);
                    } else {
                        return false
                    }
                });
            }
            return false;
        });
}

function refresh() {
    try {
        if (!localStorage.getItem('token')) throw new Error("tokenError");
        if (!localStorage.getItem('refreshToken')) throw new Error("refreshTokenError");
        let token = localStorage.getItem('refreshToken');
        return fetch(url + "auth/o/token/", {
            method: "Post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: "grant_type=refresh_token&client_secret=" + clientSecret + "&client_id=" + clientId + "&refresh_token=" + token
        })
            .then(res => {
                if (res.status === 200) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("refreshToken");
                    return res.json();
                } else {
                    return false;
                }
            })
            .then(json => {
                if (json !== false) {
                    localStorage.setItem('token', json.access_token);
                    localStorage.setItem('refreshToken', json.refresh_token);
                    return true;
                }
                return false;
            });
    }
    catch (err) {
        console.log(err);
        if (localStorage.getItem("token")) {
            localStorage.removeItem("token");
        }
        if (localStorage.getItem("refreshToken")) {
            localStorage.removeItem("refreshToken");
        }
        if (localStorage.getItem("user")) {
            localStorage.removeItem("user");
        }
        return false;
    }
}

export { logout, refresh, getUrl, getClientData, get, put, destroy, post}
