import React from "react";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Typography } from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import DesktopWindowsOutlinedIcon from '@material-ui/icons/DesktopWindowsOutlined';
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';

class Services extends React.Component {

    render() {
        return (
            <div>
                <Hidden mdDown>
                    <div style={{ position: 'relative', backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", backgroundAttachment: "fixed" }}>
                        <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 150px)" }}>
                            <Grid item xs={12} style={{}}>
                                <Grid container>
                                    <div style={{ backgroundColor: "", width: "100%", height: "500px" }}>
                                        <Grid container justify="center" >
                                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                                <Typography style={{ fontSize: "25px", marginTop: "3%" }}>
                                                    Our available services
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                                <Typography color="textSecondary" style={{ fontSize: "16px", marginTop: "" }}>
                                                    Feel free to contact our recruitments if you want additional information at <a href="mailto:recruitment@hermesgent.be"><b>recruitment@hermesgent.be</b></a>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} style={{ marginTop: "40px" }}>

                                                <Grid container>
                                                    <Grid item xs={1}>
                                                        <div style={{ backgroundColor: "#3D3D3D", marginBottom: "20px" }}>
                                                            <Grid item xs={1} style={{ marginLeft: "5%", marginRight: "5%", marginTop: "0%", marginBottom: "", height: "100px" }}>
                                                                <StorageOutlinedIcon style={{ width: "75px", height: "75px", color: "white", marginTop: "100%", marginLeft: "100%" }} />
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <div style={{ backgroundColor: "#E0E0E0", marginLeft: "-10px", height: "100px" }}>
                                                            <Typography color="textSecondary" style={{ fontSize: "25px", marginLeft: "30px" }}>
                                                                Resume Guide
                                                                </Typography>
                                                            <Typography color="textSecondary" style={{ marginTop: "10px", fontSize: "16px", marginBottom: "10px", marginLeft: "30px", marginRight: "30px" }}>
                                                                Every year hundreds of students upload their resume to our database. Gain the opportunity to browse and search through this collection and find perfect match for you.
                                                                </Typography>
                                                        </div>
                                                    </Grid>
                                                </Grid>



                                                <Grid container>
                                                    <Grid item xs={1}>
                                                        <div style={{ backgroundColor: "#3D3D3D", marginBottom: "20px" }}>
                                                            <Grid item xs={1} style={{ marginLeft: "5%", marginRight: "5%", marginTop: "0%", marginBottom: "", height: "100px" }}>
                                                                <DescriptionIcon style={{ width: "75px", height: "75px", color: "white", marginTop: "100%", marginLeft: "100%" }} />
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <div style={{ backgroundColor: "#E0E0E0", marginLeft: "-10px", height: "100px" }}>
                                                            <Typography color="textSecondary" style={{ fontSize: "25px", marginLeft: "30px" }}>
                                                                Vacancy posts
                                                                </Typography>
                                                            <Typography color="textSecondary" style={{ marginTop: "10px", fontSize: "16px", marginBottom: "10px", marginLeft: "30px", marginRight: "30px" }}>
                                                                The ability to upload vacancies so they can be displayed on our sites.
                                                                </Typography>
                                                        </div>
                                                    </Grid>
                                                </Grid>


                                                <Grid container>
                                                    <Grid item xs={1}>
                                                        <div style={{ backgroundColor: "#3D3D3D", marginBottom: "20px" }}>
                                                            <Grid item xs={1} style={{ marginLeft: "5%", marginRight: "5%", marginTop: "0%", marginBottom: "", height: "100px" }}>
                                                                <DesktopWindowsOutlinedIcon style={{ width: "75px", height: "75px", color: "white", marginTop: "100%", marginLeft: "100%" }} />
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <div style={{ backgroundColor: "#E0E0E0", marginLeft: "-10px", height: "100px" }}>
                                                            <Typography color="textSecondary" style={{ fontSize: "25px", marginLeft: "30px" }}>
                                                                Social media posts
                                                                </Typography>
                                                            <Typography color="textSecondary" style={{ marginTop: "10px", fontSize: "16px", marginBottom: "10px", marginLeft: "30px", marginRight: "30px" }}>
                                                                Advertise your company on our Facebook/Instagram/LinkedIn page and reach our students
                                                                </Typography>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Hidden>
                <Hidden lgUp>
                    <div style={{ position: 'relative', backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", backgroundAttachment: "fixed" }}>
                        <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px)" }}>
                            <Grid item xs={12} style={{}}>
                                <Grid container>
                                    <div style={{ backgroundColor: "", width: "100%", height: "500px" }}>
                                        <Grid container justify="center" >
                                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                                <Typography style={{ fontSize: "25px", marginTop: "3%" }}>
                                                    Our available services
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                                <Typography color="textSecondary" style={{ fontSize: "16px", marginTop: "" }}>
                                                    Feel free to contact our recruitments if you want additional information at <a href="mailto:recruitment@hermesgent.be"><b>recruitment@hermesgent.be</b></a>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={11} style={{ marginTop: "40px" }}>

                                                <Grid container>
                                                    <Grid item xs={3}>
                                                        <div style={{ backgroundColor: "#3D3D3D", marginBottom: "20px" }}>
                                                            <Grid item xs={1} style={{ marginLeft: "5%", marginRight: "5%", marginTop: "0%", marginBottom: "", height: "200px" }}>
                                                                <StorageOutlinedIcon style={{ width: "75px", height: "75px", color: "white", marginTop: "60px", marginLeft: "100%" }} />
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <div style={{ backgroundColor: "#E0E0E0", marginLeft: "-10px", height: "200px" }}>
                                                            <Typography color="textSecondary" style={{ fontSize: "25px", marginLeft: "30px" }}>
                                                                Resume Guide
                                                                </Typography>
                                                            <Typography color="textSecondary" style={{ marginTop: "10px", fontSize: "16px", marginBottom: "10px", marginLeft: "30px", marginRight: "30px" }}>
                                                                Every year hundreds of students upload their resume to our database. Gain the opportunity to browse and search through this collection and find perfect match for you.
                                                                </Typography>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item xs={3}>
                                                        <div style={{ backgroundColor: "#3D3D3D", marginBottom: "20px" }}>
                                                            <Grid item xs={1} style={{ marginLeft: "5%", marginRight: "5%", marginTop: "0%", marginBottom: "", height: "200px" }}>
                                                                <DescriptionIcon style={{ width: "75px", height: "75px", color: "white", marginTop: "60px", marginLeft: "100%" }} />
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <div style={{ backgroundColor: "#E0E0E0", marginLeft: "-10px", height: "200px" }}>
                                                            <Typography color="textSecondary" style={{ fontSize: "25px", marginLeft: "30px" }}>
                                                                Vacancy posts
                                                                </Typography>
                                                            <Typography color="textSecondary" style={{ marginTop: "10px", fontSize: "16px", marginBottom: "10px", marginLeft: "30px", marginRight: "30px" }}>
                                                                The ability to upload vacancies so they can be displayed on our sites.
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                </Grid>


                                                <Grid container>
                                                    <Grid item xs={3}>
                                                        <div style={{ backgroundColor: "#3D3D3D", marginBottom: "20px" }}>
                                                            <Grid item xs={1} style={{ marginLeft: "5%", marginRight: "5%", marginTop: "0%", marginBottom: "", height: "200px" }}>
                                                                <DesktopWindowsOutlinedIcon style={{ width: "75px", height: "75px", color: "white", marginTop: "60px", marginLeft: "100%" }} />
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <div style={{ backgroundColor: "#E0E0E0", marginLeft: "-10px", height: "200px" }}>
                                                            <Typography color="textSecondary" style={{ fontSize: "25px", marginLeft: "30px" }}>
                                                                Social media posts
                                                                </Typography>
                                                            <Typography color="textSecondary" style={{ marginTop: "10px", fontSize: "16px", marginBottom: "10px", marginLeft: "30px", marginRight: "30px" }}>
                                                                Advertise your company on our Facebook/Instagram/LinkedIn page and reach our students
                                                                </Typography>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Hidden>
            </div >
        );
    }
}

export default Services;
