import React from "react";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Hidden from '@material-ui/core/Hidden';
import RecruitmentR from '../static/images/RecruitmentR.png'
import {
    Link
} from "react-router-dom";
import { getUrl } from "../shared/AuthLogic";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mail: "",
            invalid: false,
            sent: false,
        };
    }

    reset = (e) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.mail)) {
            this.setState({ invalid: false });
            fetch(getUrl() + 'auth/reset/password-reset/reset_password/', {
                method: "Post",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: this.state.mail
                })
            })
                .then(res => {
                    if (res.status === 200 || res.status === 400) {
                        this.setState({ sent: true });
                        return true;
                    }
                }, reason => {
                    this.setState({ invalid: true, error: "Something went wrong, " + JSON.stringify(reason) + ", if this keeps occurring please contact ict@hermesgent.be", sent: false});
                });
        } else {
            this.setState({ invalid: true, error: "You have entered an invalid email!" });
        }
    }

    render() {
        let errorMessage;
        let page;
        if (this.state.sent) {
            page = <div>
                <Hidden lgDown>
                    <Grid container spacing={12} style={{ height: "calc(100vh - 80px - 120px)" }}>
                        <Grid item xs={5} />
                        <Grid item xs={2} style={{ textAlign: "center" }}>
                            <br />
                            <br />
                            <br />
                            <img
                                src={RecruitmentR}
                                display="block"
                                width="80"
                                height="60"
                                alt="Hermes Recruitment logo"
                                style={{ marginTop: '15px', marginLeft: "auto", marginRight: "auto", display: "block" }}
                            />
                            <br />
                            <Typography variant="h6" component="h2" >
                                Reset password
                        </Typography>
                            <br />
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom>
                                        If {this.state.mail} is registered then an email will be sent with further instructions to reset your password.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={12} >
                                        <Grid item xs={2} />
                                        <Grid item xs={8}>
                                            <Button component={Link} to="/home" style={{ color: "blue", fontSize: 11 }}>
                                                Take me back home
                                        </Button>
                                        </Grid>
                                        <Grid item xs={2} />
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={5} />
                    </Grid>
                </Hidden>
                <Hidden xlUp>
                    <Grid container spacing={12} style={{ height: "calc(100vh - 80px - 150px)", minHeight: "400px", marginTop: "50px" }} justify="center">
                        <Grid item xs={8}>
                            <img
                                src={RecruitmentR}
                                width="80"
                                height="60"
                                alt="Hermes Recruitment logo"
                                style={{ marginTop: '15px', marginLeft: "auto", marginRight: "auto", display: "block" }}
                            />
                            <br />
                            <Typography variant="h6" component="h2" style={{ textAlign: "center" }}>
                                Reset password
                        </Typography>
                            <br />
                            <Card variant="outlined" style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "300px" }}>
                                <CardContent>
                                    <Typography gutterBottom>
                                        If {this.state.mail} is registered then an email will be sent with further instructions to reset your password.
                                        </Typography>
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={12} >
                                        <Grid item xs={2} />
                                        <Grid item xs={8}>
                                            <Button component={Link} to="/home" style={{ color: "blue", fontSize: 11 }}>
                                                Take me back home
                                            </Button>
                                        </Grid>
                                        <Grid item xs={2}>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>
            </div >;
        } else {
            if (this.state.invalid) {
                errorMessage = <Typography style={{ color: "red" }}>{this.state.error}</Typography>;
            } else {
                errorMessage = <br />;
            }
            page = <div>
                <Hidden lgDown>
                    <Grid container spacing={12} style={{ height: "calc(100vh - 80px - 120px)" }}>
                        <Grid item xs={5} />
                        <Grid item xs={2}>
                            <br />
                            <br />
                            <br />
                            <img
                                src={RecruitmentR}
                                display="block"
                                width="80"
                                height="60"
                                alt="Hermes Recruitment logo"
                                style={{ marginTop: '15px', marginLeft: "auto", marginRight: "auto", display: "block" }}
                            />
                            <br />
                            <Typography variant="h6" component="h2" style={{ textAlign: "center" }}>
                                Reset password
                        </Typography>
                            <br />
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom>
                                        Email address
                                </Typography>
                                    <TextField fullWidth label="Email" name="email" size="small" variant="outlined" 
                                    onKeyPress={e => {
                                        if (e.key === "Enter") {
                                            this.reset();
                                        }
                                    }}
                                    onChange={e => this.setState({ mail: e.target.value })} value={this.state.mail} />
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={12} >
                                        <Grid item xs={2} />
                                        <Grid item xs={8} style={{ textAlign: "center" }}>
                                            {errorMessage}
                                            <Button size="small" variant="contained" onClick={this.reset} style={{ backgroundColor: "green", color: "white" }}>Reset password</Button>
                                        </Grid>
                                        <Grid item xs={2} />
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={5} />
                    </Grid>
                </Hidden>
                <Hidden xlUp>
                    <Grid container spacing={12} style={{ height: "calc(100vh - 80px - 150px)", minHeight: "400px", marginTop: "50px" }} justify="center">
                        <Grid item xs={8}>
                            <img
                                src={RecruitmentR}
                                width="80"
                                height="60"
                                alt="Hermes Recruitment logo"
                                style={{ marginTop: '15px', marginLeft: "auto", marginRight: "auto", display: "block" }}
                            />
                            <br />
                            <Typography variant="h6" component="h2" style={{ textAlign: "center" }}>
                                Reset password
                        </Typography>
                            <br />
                            <Card variant="outlined" style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "300px" }}>
                                <CardContent>
                                    <Typography gutterBottom>
                                        Email address
                        </Typography>
                                    <TextField fullWidth label="Email" name="email" size="small" variant="outlined"
                                    onChange={e => this.setState({ mail: e.target.value })} value={this.state.mail} 
                                    onKeyPress={e => {
                                        if (e.key === "Enter") {
                                            this.reset();
                                        }
                                    }}
                                    />
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={12} >
                                        <Grid item xs={2} />
                                        <Grid item xs={8} style={{ textAlign: "center" }}>
                                            {errorMessage}
                                            <Button size="small" variant="contained" onClick={this.reset} style={{ backgroundColor: "green", color: "white" }}>Reset password</Button>
                                        </Grid>
                                        <Grid item xs={2}>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>
            </div >;
        }
        return (
            <div>{page}</div>
        )
    }
}

export default LoginPage;