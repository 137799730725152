import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import Lost from "./NoMatch";
import RecruitmentR from '../static/images/RecruitmentR.png'
import { get } from "../shared/AuthLogic";

class Vacature extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            short_desc: "",
            bijlage: null,
            company_name: "",
            company_pk: null,
            doelgroep: [],
            functiebeschrijving: "",
            profiel: "",
            aanbod: "",
            interesse: "",
            is_visible: true,
            logo: null,
            soort: "",
            title: "Loading",
            locatie: "",
            client: "",
            exists: true,
            website: "https://www.hermesgent.be/",
            pk: props.par.pk,
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,

        }
        get("jobbeurs/vacatures/" + this.state.pk)
            .then(json => {
                console.log(json);
                if (json !== false) {
                    this.setState({
                        short_desc: json.short_desc,
                        bijlage: json.bijlage,
                        company_name: json.company_name,
                        company_pk: json.owner_pk,
                        doelgroep: json.doelgroep,
                        functiebeschrijving: json.functiebeschrijving,
                        profiel: json.profiel,
                        aanbod: json.aanbod,
                        interesse: json.interesse,
                        is_visible: json.is_visible,
                        logo: json.logo,
                        soort: json.soort,
                        title: json.title,
                        website: json.website,
                        locatie: json.locatie,
                        client: json.client,
                        pk: json.pk,
                        exists: true,
                    }, () => {
                        this.forceUpdate();
                    });
                } else {
                    this.setState({ exists: false});
                }
            });
    }


    render() {
        if (!this.state.exists) {
            return <Lost />;
        }
        if (!this.state.is_visible) {
            if (this.state.user) {
                if (!this.state.user.isRecruitment && this.state.user.company_pk !== this.state.pk) {
                    return <Lost />;
                }
            } else {
                return <Lost />;
            }
        }
        let token;
        let edit;
        let interests;
        let infor;
        if (this.state.doelgroep.includes("infor")) {
            infor = <Chip
                size="small"
                label="Computer science"
                color="primary"
                style={{ marginRight: "10px", marginBottom: "10px" }}
            />;
        }
        let elektromech;
        if (this.state.doelgroep.includes("elektromech")) {
            elektromech = <Chip
                size="small"
                label="Electromechanics"
                color="primary"
                style={{ marginRight: "10px", marginBottom: "10px" }}
            />;
        }
        let chem;
        if (this.state.doelgroep.includes("chem")) {
            chem = <Chip
                size="small"
                label="Chemistry"
                color="primary"
                style={{ marginRight: "10px", marginBottom: "10px" }}
            />;
        }
        let bouw;
        if (this.state.doelgroep.includes("bouw")) {
            bouw = <Chip
                size="small"
                label="Structural engineering"
                color="primary"
                style={{ marginRight: "10px", marginBottom: "10px" }}
            />;
        }
        let auto;
        if (this.state.doelgroep.includes("auto")) {
            auto = <Chip
                size="small"
                label="Automation"
                color="primary"
                style={{ marginRight: "10px", marginBottom: "10px" }}
            />;
        }
        let land;
        if (this.state.doelgroep.includes("land")) {
            land = <Chip
                size="small"
                label="Land surveyor"
                color="primary"
                style={{ marginRight: "10px", marginBottom: "10px" }}
            />;
        }
        let eict;
        if (this.state.doelgroep.includes("eict")) {
            eict = <Chip
                size="small"
                label="Electronics-ICT"
                color="primary"
                style={{ marginRight: "10px", marginBottom: "10px" }}
            />;
        }
        let etech;
        if (this.state.doelgroep.includes("etech")) {
            etech = <Grid item xs={3}>
                <Chip
                    size="small"
                    label="Electrotechnics"
                    color="primary"
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                />
            </Grid>;
        }
        if (this.state.doelgroep.length > 0) {
            interests = <div>
                {infor}
                {elektromech}
                {chem}
                {bouw}
                {auto}
                {land}
                {eict}
                {etech}
            </div>
        }
        if (this.state.user && (this.state.user.company_pk === this.state.pk || this.state.user.isRecruitment)) {
            let vis
            if (!this.state.is_visible) {
                vis = <Typography style={{ color: "red" }}>
                    <b>This page is inactive, see edit this page to make it active</b>
                </Typography>
            }
            edit = <div>
                <Grid container>
                    <Grid item xs={4}>
                        <Typography style={{ textAlign: "right", marginRight: "20px" }}>
                            <b>
                                <Link href={"/company/vacatures/" + this.state.pk + "/edit/"}>
                                    Edit this page
                                </Link>
                            </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {vis}
                    </Grid>
                </Grid>
            </div>
        }
        token = <div>
            <Link href={this.state.website} style={{ marginTop: "10px" }}>
                {this.state.website}
            </Link>
        </div>
        return (
            <div>
                <Hidden lgDown>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 150px)" }} justify="center">
                        <Grid item xs={6} style={{ marginTop: "50px" }}>
                            <Grid container spacing={12}>
                                <Grid item xs={4}>
                                    <div style={{ minHeight: "50px", minWidth: "100px", maxWidth: "150px", maxHeight: "150px", marginTop: '15px', marginBottom: '15px' }}>
                                        <img
                                            src={this.state.logo === null ? RecruitmentR : this.state.logo}
                                            alt="No logo"
                                            style={{ marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", display: "block", maxWidth: "100%", maxHeight: "100%" }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "center", marginTop: "20px" }}>
                                    <Typography variant="h3" component="h2" style={{}}>
                                        <b>{this.state.logo === null ? this.state.company_name.concat(": ").concat(this.state.title) : this.state.title}</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={12} style={{ marginTop: "20px" }}>
                                    {token}
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    {interests}
                                </Grid>
                            </Grid>
                            <Link href={this.state.user && this.state.user.company_pk === this.state.pk ? "/company/companies/" + this.state.company_pk : "/student/companies/" + this.state.company_pk}><Typography>Learn more about this company</Typography></Link>
                            <Grid item xs={12} style={{ marginTop: "10px" }} />
                            <Card variant="outlined" style={{ marginBottom: "10px" }}>
                                <CardContent>
                                    {edit}
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <h4>Short description</h4>
                                        <p>{this.state.short_desc}</p>
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        {this.state.functiebeschrijving !== "" ? <h4>Job description</h4> : null}
                                        <p>{this.state.functiebeschrijving}</p>
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        {this.state.profiel !== "" ? <h4>Profile</h4> : null}
                                        <p>{this.state.profiel}</p>
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        {this.state.aanbod !== "" ? <h4>What do we offer</h4> : null}
                                        <p>{this.state.aanbod}</p>
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        {this.state.client !== "" ? <h4>Our client</h4> : null}
                                        <p>{this.state.client}</p>
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        {this.state.interesse !== "" ? <h4>Interested?</h4> : null}
                                        <p>{this.state.interesse}</p>
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        {this.state.locatie !== "" ? <h4>Location</h4> : null}
                                        <p>{this.state.locatie}</p>
                                    </Typography>
                                    {this.state.bijlage !== null ?
                                        <Typography style={{ textAlign: "right", marginRight: "20px" }}>
                                            <b>
                                                <Link href={this.state.bijlage}>
                                                    More information
                                                </Link>
                                            </b>
                                        </Typography>
                                        : <div/>}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden xlUp>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 150px)", marginTop: "50px"}} justify="center">
                        <Grid item xs={10} style={{ marginTop: "50px" }}>
                            <Grid container spacing={12}>
                                <Grid item xs={4}>
                                    <div style={{ minHeight: "20px", minWidth: "20px", maxWidth: "150px", maxHeight: "150px", marginTop: '15px'}}>
                                        <img
                                            src={this.state.logo === null ? RecruitmentR : this.state.logo}
                                            alt="No logo"
                                            style={{ marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", display: "block", maxWidth: "100%", maxHeight: "100%" }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "center", marginTop: "20px" }}>
                                    <Typography style={{ fontSize: "calc(12px + 1.5vw)" }}>
                                        <b>{this.state.logo === null ? this.state.company_name.concat(": ").concat(this.state.title) : this.state.title}</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={12} style={{ marginTop: "20px" }}>
                                    {token}
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    {interests}
                                </Grid>
                            </Grid>
                            <Link href={this.state.user && this.state.user.company_pk === this.state.pk ? "/company/companies/" + this.state.company_pk : "/student/companies/" + this.state.company_pk}><Typography>Learn more about this company</Typography></Link>
                            <Grid item xs={12} style={{ marginTop: "10px" }} />
                            <Card variant="outlined" style={{ marginBottom: "10px" }}>
                                <CardContent>
                                    {edit}
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <h4>Short description</h4>
                                        <p>{this.state.short_desc}</p>
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        {this.state.functiebeschrijving !== "" ? <h4>Job description</h4> : null}
                                        <p>{this.state.functiebeschrijving}</p>
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        {this.state.profiel !== "" ? <h4>Profile</h4> : null}
                                        <p>{this.state.profiel}</p>
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        {this.state.aanbod !== "" ? <h4>What do we offer</h4> : null}
                                        <p>{this.state.aanbod}</p>
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        {this.state.client !== "" ? <h4>Our client</h4> : null}
                                        <p>{this.state.client}</p>
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        {this.state.interesse !== "" ? <h4>Interested?</h4> : null}
                                        <p>{this.state.interesse}</p>
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        {this.state.locatie !== "" ? <h4>Location</h4> : null}
                                        <p>{this.state.locatie}</p>
                                    </Typography>
                                    {this.state.bijlage !== null ?
                                        <Typography style={{ textAlign: "right", marginRight: "20px" }}>
                                            <b>
                                                <Link href={this.state.bijlage}>
                                                    More information
                                                </Link>
                                            </b>
                                        </Typography>
                                        : <div/>}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>
            </div >
        );
    }
}

export default Vacature;
