import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Routing from './Routing'
import * as serviceWorker from './serviceWorker';
import CssBaseline from '@material-ui/core/CssBaseline';
import RecruitmentR from './static/images/RecruitmentR.png'
import { Helmet } from "react-helmet";

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
    <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
    <Helmet>
      <meta charSet="utf-8" />
      <title>Hermes Recruitment</title>
      <link rel="icon" type="image/png" href={RecruitmentR} sizes="40x40" />
    </Helmet>
    <Routing />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
