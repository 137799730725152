import React from "react";
import Home from "./shared/Home"
import StudentHome from "./Student/Home"
import CompanyHome from "./Company/Home"
import Login from "./shared/Login"
import Logout from "./shared/Logout"
import Admin from "./admin/Portal"
import AdminAdd from "./admin/Add"
import AdminSearch from "./admin/Search"
import JobAndStage from "./shared/JobAndStage"
import Services from "./Company/Services"
import StudentNavbar from "./Student/StudentNavbar"
import EmptyNavbar from "./shared/NavbarEmpty"
import CompanyNavbar from "./Company/CompanyNavbar"
import Footer from "./shared/Footer"
import ResetPassword from "./shared/PasswordReset"
import ResetConfirmation from "./shared/ResetConfirmation"
import Internships from "./Student/Internships"
import Jobs from "./Student/Jobs"
import Companies from "./shared/Companies"
import About from "./shared/About"
import Lost from "./shared/NoMatch"
import Company from "./shared/Company"
import EditCompany from "./Company/EditCompany"
import PrivateCompanyInfo from "./Company/PrivateCompanyInfo"
import ManageCompany from "./Company/ManageCompany"
import Vacature from "./shared/Vacature"
import EditVacature from "./Company/EditVacature"
import MyVacancies from "./Company/MyVacancies"
import AddCompanyAccount from "./admin/AddCompanyAccount"
import CreateVacature from "./Company/CreateVacature"
import Welcome from "./Company/Welcome"
import CompanyVacancies from "./Student/CompanyVacancies"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


// Some folks find value in a centralized route config.
// A route config is just data. React is great at mapping
// data into components, and <Route> is a component.

// Our route config is just an array of logical "routes"
// with `path` and `component` props, ordered the same
// way you'd do inside a `<Switch>`.
export default function Routes() {

  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route exact path="/home">
            <EmptyNav component={Home} />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/reset">
            <ResetPassword />
          </Route>
          <Route exact path="/reset/confirmation/:token">
            <ResetConfirmation />
          </Route>
          <Route exact path="/logout">
            <Logout />
          </Route>
          <Route exact path="/student">
            <StudentNav component={StudentHome} />
          </Route>
          <Route exact path="/student/jobstageevent">
            <StudentNav component={JobAndStage} />
          </Route>
          <Route exact path="/student/jobs">
            <StudentNav component={Jobs} />
          </Route>
          <Route exact path="/student/vacatures/:pk">
            <StudentNav component={Vacature} />
          </Route>
          <Route exact path="/student/internships">
            <StudentNav component={Internships} />
          </Route>
          <Route exact path="/student/companies">
            <StudentNav component={Companies} isStudent={true} />
          </Route>
          <Route exact path="/student/companies/:pk">
            <StudentNav component={Company} />
          </Route>
          <Route exact path="/student/companies/:pk/vacatures/">
            <StudentNav component={CompanyVacancies} />
          </Route>
          <Route exact path="/student/companies/:pk/edit">
            <RestrictedParameterRoute component={EditCompany} type={"student"} />
          </Route>
          <Route exact path="/student/companies/:pk/edit/personal">
            <RestrictedParameterRoute component={PrivateCompanyInfo} type={"student"} />
          </Route>
          <Route exact path="/student/about">
            <StudentNav component={About} />
          </Route>
          <Route exact path="/company">
            <CompanyNav component={CompanyHome} />
          </Route>
          <Route exact path="/company/welcome">
            <EmptyNav component={Welcome} />
          </Route>
          <Route exact path="/company/services">
            <CompanyNav component={Services} />
          </Route>
          <Route exact path="/company/jobstageevent">
            <CompanyNav component={JobAndStage} />
          </Route>
          <Route exact path="/company/companies">
            <CompanyNav component={Companies} isStudent={false} />
          </Route>
          <Route exact path="/company/about">
            <CompanyNav component={About} />
          </Route>
          <Route exact path="/company/companies/:pk/edit">
            <RestrictedParameterRoute component={EditCompany} type={"company"} />
          </Route>
          <Route exact path="/company/companies/:pk/manage">
            <RestrictedParameterRoute component={ManageCompany} type={"company"} />
          </Route>
          <Route exact path="/company/companies/:pk/edit/personal">
            <RestrictedParameterRoute component={PrivateCompanyInfo} type={"company"} />
          </Route>
          <Route exact path="/company/companies/:pk">
            <CompanyNav component={Company} />
          </Route>
          <Route exact path="/company/vacatures/:pk/edit">
            <RestrictedRoute component={EditVacature} condition={
              localStorage.getItem("user") !== null && (JSON.parse(localStorage.getItem("user")).isRecruitment || "company_pk" in JSON.parse(localStorage.getItem("user")))
            } />
          </Route>
          <Route exact path="/company/companies/:pk/vacatures/add">
            <RestrictedParameterRoute component={CreateVacature} type={"company"} />
          </Route>
          <Route exact path="/company/companies/:pk/vacatures">
            <RestrictedParameterRoute component={MyVacancies} type={"company"} />
          </Route>
          <Route exact path="/admin/add_company_account/:pk">
            <RestrictedParameterRoute component={AddCompanyAccount} type={"company"} />
          </Route>
          <Route exact path="/admin">
            <RestrictedRoute component={Admin} condition={
              localStorage.getItem("user") !== null && JSON.parse(localStorage.getItem("user")).isRecruitment
            } />
          </Route>
          <Route exact path="/admin/add/">
            <RestrictedRoute component={AdminAdd} condition={
              localStorage.getItem("user") !== null && JSON.parse(localStorage.getItem("user")).isRecruitment
            } />
          </Route>
          <Route exact path="/admin/search/">
            <RestrictedRoute component={AdminSearch} condition={
              localStorage.getItem("user") !== null && JSON.parse(localStorage.getItem("user")).isRecruitment
            } />
          </Route>
          <Route>
            <EmptyNav component={Lost} />
          </Route>
        </Switch>
      </div>
    </Router >
  );
}

function EmptyNav(props) {
  let params = useParams();
  return <div>
    <EmptyNavbar />
    <props.component par={params} />
    <Footer />
  </div>
}

function StudentNav(props) {
  let params = useParams();
  return <div>
    <StudentNavbar />
    <props.component par={params} props={props} />
    <Footer />
  </div>
}

function CompanyNav(props) {
  let params = useParams();
  return <div>
    <CompanyNavbar />
    <props.component par={params} props={props} />
    <Footer />
  </div>
}

function RestrictedRoute(props) {
  let params = useParams();
  return <div>
    {
      props.condition ?
        <div>
          <StudentNavbar />
          <props.component par={params} />
          <Footer />
        </div> :
        <div>
          <StudentNavbar />
          <Lost />
          <Footer />
        </div>
    }
  </div>
}

function RestrictedParameterRoute(props) {
  let params = useParams();
  let { pk } = useParams();
  if (localStorage.getItem("user")) {
    let user = JSON.parse(localStorage.getItem("user"));
    if (("company_pk" in user && pk === user.company_pk.toString()) || user.isRecruitment) {
      if (props.type === "company") {
        return <div>
          <CompanyNavbar />
          <props.component par={params} />
          <Footer />
        </div>
      } else {
        return <div>
          <StudentNavbar />
          <props.component par={params} />
          <Footer />
        </div>
      }
    }
  }
  return <div>
    <StudentNavbar />
    <Lost />
    <Footer />
  </div>;
}

