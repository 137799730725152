import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Lost from "./NoMatch";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import RecruitmentR from '../static/images/RecruitmentR.png'
import { get } from "../shared/AuthLogic";

class Company extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: "You can contact me here",
            desc_long: "This is a long description",
            history: "",
            international: false,
            is_visible: true,
            location: "",
            logo: "",
            masterproeven: false,
            name: "title",
            website: "https://www.hermesgent.be/",
            pk: props.par.pk,
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
            exists: true,
            openings: 0,
            interest: []
        }
        get("jobbeurs/companies/" + this.state.pk)
            .then(json => {
                if (json !== false) {
                    this.setState({
                        contact: json.contact,
                        desc_long: json.desc_long,
                        history: json.history,
                        international: json.international,
                        is_visible: json.is_visible,
                        location: json.location,
                        logo: json.logo,
                        masterproeven: json.masterproeven,
                        name: json.name,
                        website: json.website,
                        pk: json.pk,
                        interest: json.interest,
                        openings: json.vacatures,
                        exists: true,
                    }, () => {
                        this.forceUpdate();
                    });
                } else {
                    this.setState({exists: false});
                }
            });
    }


    render() {
        if (!this.state.exists) {
            return <Lost />;
        }
        if (!this.state.is_visible) {
            if (this.state.user) {
                if (!this.state.user.isRecruitment && this.state.user.company_pk !== this.state.pk) {
                    return <Lost />;
                }
            } else {
                return <Lost />;
            }
        }
        let international;
        let masterproeven;
        let token;
        let edit;
        if (this.state.international) {
            international = <Chip
                size="small"
                label="International"
                color="secondary"
                style={{ marginRight: "10px", marginBottom: "10px" }}
            />
        }
        if (this.state.masterproeven) {
            masterproeven = <Chip
                size="small"
                label="Master's thesis"
                color="secondary"
                style={{ marginRight: "10px", marginBottom: "10px" }}
            />;
        }
        let interests;
        let infor;
        if (this.state.interest.includes("infor")) {
            infor = <Chip
                size="small"
                label="Computer science"
                color="primary"
                style={{ marginRight: "10px", marginBottom: "10px" }}
            />;
        }
        let elektromech;
        if (this.state.interest.includes("elektromech")) {
            elektromech = <Chip
                size="small"
                label="Electromechanics"
                color="primary"
                style={{ marginRight: "10px", marginBottom: "10px" }}
            />;
        }
        let chem;
        if (this.state.interest.includes("chem")) {
            chem = <Chip
                size="small"
                label="Chemistry"
                color="primary"
                style={{ marginRight: "10px", marginBottom: "10px" }}
            />;
        }
        let bouw;
        if (this.state.interest.includes("bouw")) {
            bouw = <Chip
                size="small"
                label="Structural engineering"
                color="primary"
                style={{ marginRight: "10px", marginBottom: "10px" }}
            />;
        }
        let auto;
        if (this.state.interest.includes("auto")) {
            auto = <Chip
                size="small"
                label="Automation"
                color="primary"
                style={{ marginRight: "10px", marginBottom: "10px" }}
            />;
        }
        let land;
        if (this.state.interest.includes("land")) {
            land = <Chip
                size="small"
                label="Land surveyor"
                color="primary"
                style={{ marginRight: "10px", marginBottom: "10px" }}
            />;
        }
        let eict;
        if (this.state.interest.includes("eict")) {
            eict = <Chip
                size="small"
                label="Electronics-ICT"
                color="primary"
                style={{ marginRight: "10px", marginBottom: "10px" }}
            />;
        }
        let etech;
        if (this.state.interest.includes("etech")) {
            etech = <Grid item xs={3}>
                <Chip
                    size="small"
                    label="Electrotechnics"
                    color="primary"
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                />
            </Grid>;
        }
        if (this.state.interest.length > 0) {
            interests = <div>
                {infor}
                {elektromech}
                {chem}
                {bouw}
                {auto}
                {land}
                {eict}
                {etech}
            </div>
        }
        if (this.state.user && (this.state.user.company_pk === this.state.pk || this.state.user.isRecruitment)) {
            let vis
            if (!this.state.is_visible) {
                vis = <Typography style={{ color: "red" }}>
                    This page is inactive, <Link href={"/company/companies/" + this.state.pk + "/edit"} style={{ color: "red" }}><b>Click here</b></Link> to edit it
                </Typography>
            }
            edit = <div>
                <Grid container>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href={"/company/companies/" + this.state.pk + "/manage/"}>
                                My company
                            </Link>
                            <Typography color="textPrimary">See my company</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={12}>
                        {vis}
                    </Grid>
                </Grid>
            </div>
        }
        if (this.state.international || this.state.masterproeven) {
            token = <div>
                {international}
                {masterproeven}
                <Link href={this.state.website} style={{ marginTop: "10px" }}>
                    {this.state.website}
                </Link>
            </div>
        } else {
            token = <div>
                <Link href={this.state.website} style={{ marginTop: "10px" }}>
                    {this.state.website}
                </Link>
            </div>
        }
        return (
            <div>
                <Hidden lgDown>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 150px)" }} justify="center">
                        <Grid item xs={6} style={{ marginTop: "50px" }}>
                            <Grid container spacing={12}>
                                <Grid item xs={12}>
                                {edit}
                                </Grid>
                                <Grid item xs={4}>
                                    <div style={{ minHeight: "50px", minWidth: "100px", maxWidth: "150px", maxHeight: "150px", marginTop: '15px', marginBottom: '15px' }}>
                                        <img
                                            src={this.state.logo === null ? RecruitmentR : this.state.logo}
                                            alt="No logo"
                                            style={{ marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", display: "block", maxWidth: "100%", maxHeight: "100%" }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "center", marginTop: "20px" }}>
                                    <Typography variant="h3" component="h2" style={{}}>
                                        <b>
                                            {this.state.name}
                                        </b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={12} style={{ marginTop: "20px" }}>
                                    {token}
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    {interests}
                                </Grid>
                                <Grid item xs={12} style={{textAlign: "right"}}>
                                    {this.state.openings > 0 ? <Link href={this.state.user && this.state.user.company_pk === this.state.pk ? "/company/companies/" + this.state.pk + "/vacatures/" : "/student/companies/" + this.state.pk + "/vacatures/"}>{this.state.openings} open vacancies</Link> : null}
                                </Grid>
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: "10px" }}>
                            </Grid>
                            <Card variant="outlined" style={{ marginBottom: "10px" }}>
                                <CardContent>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        <p>{this.state.desc_long}</p>
                                    </Typography>
                                    {this.state.contact.length > 0 || (this.state.location !== null && this.state.location.length > 0)
                                        ?
                                        <Grid container spacing={12} style={{ marginTop: "10px" }}>
                                            <Divider variant="middle" style={{ marginTop: "30px", marginBottom: "20px" }} />
                                            {this.state.contact.length > 0 ?
                                                <Grid item xs={5}>
                                                    <Typography style={{ textAlign: "center", marginBottom: "20px", whiteSpace: "pre-line" }}>
                                                        <b>
                                                            Contact
                                            </b>
                                                    </Typography>
                                                    <Typography style={{ marginLeft: "10px", whiteSpace: "pre-line" }}>
                                                        {this.state.contact}
                                                    </Typography>
                                                </Grid>
                                                :
                                                <div />
                                            }
                                            {(this.state.location !== null && this.state.location.length > 0) && this.state.contact.length > 0 ?
                                                <Grid item xs={2} >
                                                    <Divider style={{ marginLeft: "auto", marginRight: "auto" }} orientation="vertical" />
                                                </Grid>
                                                : <div />
                                            }
                                            {(this.state.location !== null && this.state.location.length > 0) ?
                                                <Grid item xs={5} style={{ marginLeft: "-5%" }}>
                                                    <Typography style={{ textAlign: "center", marginBottom: "20px" }}>
                                                        <b>
                                                            Location
                                                    </b>
                                                    </Typography>
                                                    <Typography style={{ whiteSpace: "pre-line" }}>
                                                        {this.state.location}
                                                    </Typography>
                                                </Grid>
                                                : <div />
                                            }
                                        </Grid>
                                        :
                                        <div />}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden xlUp>
                    <Grid container spacing={12} style={{ minHeight: "calc(100vh - 80px - 150px)", marginTop: "50px" }} justify="center">
                        <Grid item xs={10}>
                            <Grid container spacing={12}>
                                <Grid item xs={12}>
                                {edit}
                                </Grid>
                                <Grid item xs={4}>
                                    <div style={{ minHeight: "20px", minWidth: "20px", maxHeight: "150px", maxWidth: "150px", marginTop: '15px', marginBottom: '15px' }}>
                                        <img
                                            src={this.state.logo === null ? RecruitmentR : this.state.logo}
                                            alt="No logo"
                                            style={{ marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", display: "block", maxWidth: "100%", maxHeight: "100%" }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "center", marginTop: "50px" }}>
                                    <Typography style={{ fontSize: "calc(12px + 1.5vw)" }}>
                                        <b>
                                            {this.state.name}
                                        </b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={12} style={{ marginTop: "20px" }}>
                                    {token}
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: "10px" }}>
                                    {interests}
                                </Grid>
                                <Grid item xs={12} style={{textAlign: "right"}}>
                                    {this.state.openings > 0 ? <Link href={this.state.user && this.state.user.company_pk === this.state.pk ? "/company/companies/" + this.state.pk + "/vacatures/" : "/student/companies/" + this.state.pk + "/vacatures/"}>{this.state.openings} open vacancies</Link> : null}
                                </Grid>
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: "10px" }}>
                            </Grid>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        {this.state.desc_long}
                                    </Typography>

                                    {this.state.contact.length > 0 || (this.state.location !== null && this.state.location.length > 0)
                                        ?
                                        <Grid container spacing={12} style={{ marginTop: "10px" }}>
                                            <Divider variant="middle" style={{ marginTop: "30px", marginBottom: "20px" }} />
                                            {this.state.contact.length > 0 ?
                                                <Grid item xs={5}>
                                                    <Typography style={{ textAlign: "center", marginBottom: "20px", whiteSpace: "pre-line" }}>
                                                        <b>
                                                            Contact
                                            </b>
                                                    </Typography>
                                                    <Typography style={{ marginLeft: "10px", whiteSpace: "pre-line" }}>
                                                        {this.state.contact}
                                                    </Typography>
                                                </Grid>
                                                :
                                                <div />
                                            }
                                            {(this.state.location !== null && this.state.location.length > 0) && this.state.contact.length > 0 ?
                                                <Grid item xs={2} >
                                                    <Divider style={{ marginLeft: "auto", marginRight: "auto" }} orientation="vertical" />
                                                </Grid>
                                                : <div />
                                            }
                                            {(this.state.location !== null && this.state.location.length > 0) ?
                                                <Grid item xs={5} style={{ marginLeft: "-5%" }}>
                                                    <Typography style={{ textAlign: "center", marginBottom: "20px" }}>
                                                        <b>
                                                            Location
                                                    </b>
                                                    </Typography>
                                                    <Typography style={{ whiteSpace: "pre-line", marginLeft: "20%" }}>
                                                        {this.state.location}
                                                    </Typography>
                                                </Grid>
                                                : <div />
                                            }
                                        </Grid>
                                        :
                                        <div />}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Hidden>
            </div >
        );
    }
}

export default Company;
